import React, { memo, useCallback, useContext } from 'react';
import { connectModal } from '../contexts/modal/connectModal';
import {
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  BlockOverflowYEnum,
  ButtonTypeEnum,
  ButtonVariantEnum,
  ModalsEnum,
} from '../config/enums';
import Modal from '../components/modal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { fetchInstance } from '../utils/axios';
import { api } from '../config/api';
import { queries } from '../config/queries';
import { toast } from 'react-toastify';
import { Form } from '../styles/components';
import Block from '../components/block';
import Button from '../components/button';
import Input from '../components/input';
import { ModalContext } from '../contexts/modal';

const schema = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required(),
  course_id: yup.string().required(),
  module_id: yup.string().required(),
});

const TopicModal = memo(({ handleHide, ...props }: any) => {
  const { showModal } = useContext(ModalContext);
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: props.id || '',
      name: props.name || '',
      course_id: props.course_id,
      module_id: props.module_id,
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: any) =>
      fetchInstance({
        method: data.id ? 'PUT' : 'POST',
        url: api.topicsCRUD(data.id),
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.moduleDetails);
        handleHide();
        toast.success('Тема успешно сохранена!');
      },
    },
  );

  const removeMutation = useMutation(
    () =>
      fetchInstance({
        method: 'DELETE',
        url: api.topicsCRUD(props.id),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.moduleDetails);
        handleHide();
        toast.success('Тема удалена!');
      },
    },
  );

  const onSubmit = useCallback(
    (data: any) => {
      mutation.mutate(data);
    },
    [mutation],
  );

  return (
    <Modal title={`${props.id ? 'Редактирование' : 'Добавление'} темы`} handleHide={handleHide}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Block
          flexDirection={BlockFlexDirectionEnum.COLUMN}
          justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
          height={'100%'}
          overflowY={BlockOverflowYEnum.AUTO}
        >
          <Block gap={30} flexDirection={BlockFlexDirectionEnum.COLUMN}>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Название'}
                  placeholder={'Введите название'}
                />
              )}
              name={'name'}
              control={control}
            />
          </Block>
          <Block
            gap={15}
            justifyContent={
              props.id ? BlockJustifyContentEnum.SPACE_BETWEEN : BlockJustifyContentEnum.FLEX_END
            }
          >
            {props.id && (
              <Button
                variant={ButtonVariantEnum.WARNING}
                onClick={() => {
                  showModal(ModalsEnum.CONFIRMATION_MODAL, {
                    title: 'Удаление темы',
                    description: `Вы действительно хотите удалить тему?`,
                    onClick: () => removeMutation.mutate(),
                  });
                }}
              >
                Удалить
              </Button>
            )}
            <Block gap={15}>
              <Button width={'auto'} variant={ButtonVariantEnum.OUTLINED} onClick={handleHide}>
                Отменить
              </Button>
              <Button width={'auto'} type={ButtonTypeEnum.SUBMIT} disabled={mutation.isLoading}>
                Сохранить
              </Button>
            </Block>
          </Block>
        </Block>
      </Form>
    </Modal>
  );
});

export default connectModal(ModalsEnum.TOPIC_MODAL)(TopicModal);
