import React, { FC, memo } from 'react';
import { ExerciseTabEnum } from '../../config/enums';
import { useExercise } from '../../store/exercise';
import Theory from './Theory';
import Description from './Description';
import Code from './Code';
import Autotest from './Autotest';
import TaskCode from './TaskCode';

interface Props {
  tab: ExerciseTabEnum;
}

const ExerciseModule: FC<Props> = memo(({ tab }) => {
  const meta = useExercise((state) => state.meta);

  if (!meta) return null;

  if (tab === ExerciseTabEnum.THEORY) {
    return <Theory />;
  } else if (tab === ExerciseTabEnum.TASK) {
    return <Description />;
  } else if (tab === ExerciseTabEnum.CODE) {
    return <Code />;
  } else if (tab === ExerciseTabEnum.AUTOTEST) {
    return <Autotest />;
  } else if (tab === ExerciseTabEnum.TASK_CODE) {
    return <TaskCode />;
  }

  return <div>{tab}</div>;
});

export default ExerciseModule;
