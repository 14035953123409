import React, { FC, memo } from 'react';
import Card from '../card';
import Block from '../block';
import {
  BlockAlignItemsEnum,
  BlockJustifyContentEnum,
  CardBorderTypeEnum,
  TypographyVariantEnum,
} from '../../config/enums';
import Typography from '../typography';

interface Props {
  onClick: VoidFunction;
}

const AddCard: FC<Props> = memo(({ onClick }) => {
  return (
    <Card onClick={onClick} borderType={CardBorderTypeEnum.DASHED} isTransparent>
      <Block
        alignItems={BlockAlignItemsEnum.CENTER}
        justifyContent={BlockJustifyContentEnum.CENTER}
        height={'100%'}
      >
        <Typography color={'rgba(105, 108, 255, 0.50)'} fontSize={96}>
          +
        </Typography>
      </Block>
    </Card>
  );
});

export default AddCard;
