import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Watch } from 'react-loader-spinner';
import { theme } from '../../styles/theme';

const Loader: FC = memo(() => {
  return (
    <Wrapper>
      <Watch
        height='80'
        width='80'
        radius='48'
        color={theme.colors.primary}
        ariaLabel='watch-loading'
        visible={true}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default Loader;
