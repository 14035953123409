import React, { FC, memo } from 'react';
import { TableFieldsType } from '../../../config/interfaces';
import styled from 'styled-components';
import TableBodyRow from './Row';

interface Props {
  fields: TableFieldsType[];
  data: any[];
}

const TableBody: FC<Props> = memo(({ fields, data }) => {
  return (
    <TBodyContainer>
      {data.map((el, idx) => (
        <TBodyRow key={el.id}>
          <TableBodyRow row={el} rowIndex={idx} fields={fields} />
        </TBodyRow>
      ))}
    </TBodyContainer>
  );
});

const TBodyContainer = styled.tbody`
  overflow-y: auto;
`;

const TBodyRow = styled.tr`
  height: 50px;
  border-bottom: 1px solid rgba(175, 175, 175, 0.25);
`;

export default TableBody;
