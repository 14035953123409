import React, { FC, memo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Typography from '../typography';
import { InputTypeEnum, TypographyVariantEnum } from '../../config/enums';
import EyeIcon from '../../icons/Eye';
import { FormControlInterface } from '../../config/interfaces';

interface Props {
  label?: string;
  placeholder?: string;
  type?: InputTypeEnum;
  disabled?: boolean;
}

const Input: FC<Props & FormControlInterface> = memo(
  ({ label, placeholder, type = InputTypeEnum.TEXT, value, onChange, disabled, error }) => {
    const inputRef = useRef(null);

    const changeTypePassword = useCallback(() => {
      const inputType = inputRef.current.type;
      if (inputType === type) {
        inputRef.current.type = 'text';
      } else {
        inputRef.current.type = type;
      }
    }, [type]);

    const handleChange = useCallback(
      (e) => {
        if (!disabled) {
          let val = e.target.value;
          if (type === InputTypeEnum.NUMBER) {
            val = Number(e.target.value);
          }
          onChange(val);
        }
      },
      [onChange, disabled, type],
    );

    return (
      <Wrapper>
        {label && (
          <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE}>
            {label}
          </Typography>
        )}
        <InputWrapper
          value={value}
          onChange={handleChange}
          ref={inputRef}
          placeholder={placeholder}
          type={type}
          padding={type === InputTypeEnum.PASSWORD ? '10px 40px 10px 10px' : '10px'}
          error={!!error?.message}
        />
        {type === InputTypeEnum.PASSWORD && (
          <IconWrapper onClick={changeTypePassword}>
            <EyeIcon />
          </IconWrapper>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  position: relative;
`;

const InputWrapper = styled.input<{ padding: string; error: boolean }>`
  height: 40px;
  width: 100%;
  padding: ${({ padding }) => padding};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.main};
  outline: none;
  border: 1px solid ${({ error }) => (error ? 'rgba(255, 0, 0, 0.5)' : 'rgba(51, 56, 60, 0.2)')};
  border-radius: 5px;
`;

const IconWrapper = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
`;

export default Input;
