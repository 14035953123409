import React, { FC, memo, PropsWithChildren, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Typography from '../typography';
import { ButtonTypeEnum, ButtonVariantEnum } from '../../config/enums';

interface Props {
  width?: string;
  type?: ButtonTypeEnum;
  variant?: ButtonVariantEnum;
  onClick?: (e: any) => void;
  height?: number;
  disabled?: boolean;
  color?: string;
}

const Button: FC<PropsWithChildren & Props> = memo(
  ({
    children,
    width = 'auto',
    type = ButtonTypeEnum.BUTTON,
    variant = ButtonVariantEnum.CONTAINED,
    onClick,
    height = 40,
    disabled = false,
  }) => {
    const color = useMemo(() => {
      if (variant === ButtonVariantEnum.OUTLINED) {
        return '#696CFF';
      }
      return '#fff';
    }, [variant]);

    const handleClick = useCallback(
      (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (!disabled) {
          onClick(e);
        }
      },
      [onClick, disabled],
    );

    return (
      <Wrapper
        width={width}
        type={type}
        variant={variant}
        onClick={onClick && handleClick}
        height={height}
        disabled={disabled}
      >
        <Typography color={color}>{children}</Typography>
      </Wrapper>
    );
  },
);

const Wrapper = styled.button<{
  width: string;
  variant: ButtonVariantEnum;
  height: number;
  disabled: boolean;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 5px;
  background: ${({ theme, variant, disabled }) => {
    if (disabled) {
      return 'rgba(160, 160, 160, 0.5)';
    } else if (variant === ButtonVariantEnum.OUTLINED) {
      return 'transparent';
    } else if (variant === ButtonVariantEnum.WARNING) {
      return theme.colors.warning;
    }
    return theme.colors.primary;
  }};
  color: ${({ theme, variant }) => {
    if (variant === ButtonVariantEnum.OUTLINED) {
      return 'transparent';
    }
    return theme.colors.primary;
  }};
  padding: 0 30px;
  border: 1px solid
    ${({ variant }) =>
      variant === ButtonVariantEnum.WARNING
        ? 'rgba(247, 84, 46, 0.5)'
        : 'rgba(105, 108, 255, 0.5)'};
`;

export default Button;
