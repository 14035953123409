import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { queries } from '../../config/queries';
import { fetchInstance } from '../../utils/axios';
import { api } from '../../config/api';
import { useApp } from '../../store/app';
import Block from '../../components/block';
import { BlockFlexDirectionEnum, ExerciseTabEnum } from '../../config/enums';
import VerticalTabs from '../../components/verticalTabs';
import {
  defaultFileName,
  defaultHtmlTemplate,
  exerciseTabsListAdmin,
  exerciseTabsListUser,
  leftMenuEditorId,
} from '../../config/consts';
import ExerciseModule from '../../modules/exercise';
import { useExercise } from '../../store/exercise';
import Loader from '../../components/loader';
import Control from './Control';
import { ModalContext } from '../../contexts/modal';
import { ExerciseContext } from '../../contexts/exerciseContext';

const Exercise: FC = memo(() => {
  const { showModal } = useContext(ModalContext);
  const setBreadcrumbs = useApp((state) => state.setBreadcrumbs);
  const setMeta = useExercise((state) => state.setMeta);
  const clearMeta = useExercise((state) => state.clearMeta);
  const isAdmin = useApp((state) => state.isAdmin);
  const navigate = useNavigate();
  const { exerciseId, tab: tabParams, moduleId } = useParams();
  const setExerciseUserId = useExercise((state) => state.setExerciseUserId);
  const [completed, setCompleted] = useState(false);

  const menu = useMemo(
    () => (isAdmin ? exerciseTabsListAdmin : exerciseTabsListUser(showModal)),
    [isAdmin, showModal],
  ) as any;

  const [tab, setTab] = useState(
    menu.find((el) => el.link && tabParams && el.link.indexOf(tabParams) !== -1)?.id || 'theory',
  );

  const { data, isLoading } = useQuery(
    [queries.exerciseDetails, exerciseId],
    () =>
      fetchInstance({
        method: 'GET',
        url: api.exercisesCrud(exerciseId),
      }),
    {
      cacheTime: 0,
    },
  );

  useEffect(() => {
    clearMeta();
    return () => {
      setExerciseUserId(null);
    };
  }, []);

  const meta = useMemo(() => data?.data, [data]);

  useEffect(() => {
    const handleMeta = async () => {
      if (meta) {
        let data = { ...meta };
        if (isAdmin && !data?.sources?.[defaultFileName]) {
          const response = await fetchInstance({
            method: 'PUT',
            url: api.exercisesCrud(exerciseId),
            data: {
              sources: {
                [defaultFileName]: {
                  editable: true,
                  removable: false,
                  content: defaultHtmlTemplate,
                },
              },
            },
          });
          data = response.data;
        }
        setMeta({
          theory: data.theory || '',
          description: data.description || '',
          sources: data.sources || {},
          tests: data.tests || [],
        });
      }
    };
    handleMeta();
  }, [meta, isAdmin]);

  useEffect(() => {
    if (meta) {
      const element = menu.find((el) => el.id === tab);
      setBreadcrumbs([
        { label: 'Программы', action: () => navigate('/') },
        {
          label: meta.module.name,
          action: () => navigate(`/module/${meta.module.id}`),
        },
        {
          label: meta.topic.name,
          action: () => navigate(`/module/${meta.module.id}`),
        },
        {
          label: meta.name,
          action: () => navigate(`/module/${meta.module.id}/${exerciseId}/theory`),
        },
        {
          label: element.label,
          active: true,
        },
      ]);
    }
  }, [meta, isAdmin, tab, exerciseId, menu]);

  const handleTab = useCallback(
    (id) => {
      const element = menu.find((el) => el.id === id);
      navigate(`/module/${moduleId}/${exerciseId}/${element.link}`);
      setTab(id);
    },
    [menu, moduleId, exerciseId],
  );

  if (isLoading) return <Loader />;

  return (
    <ExerciseContext.Provider value={{ exerciseMeta: meta, setCompleted, completed }}>
      <Block height={'100%'}>
        <Block width={'60px'} background={'#F5F5F9'} padding={'10px'} id={leftMenuEditorId}>
          <VerticalTabs
            list={isAdmin ? exerciseTabsListAdmin : exerciseTabsListUser(showModal)}
            tab={tab}
            onChange={handleTab}
          />
        </Block>
        <Block width={'100%'} flexDirection={BlockFlexDirectionEnum.COLUMN}>
          <Block height={`calc(100% - ${tab === ExerciseTabEnum.AUTOTEST ? 0 : 60}px)`}>
            <ExerciseModule tab={tab} />
          </Block>
          {/*<Divider color={'rgba(105, 108, 255, 0.20)'} />*/}
          {tab !== ExerciseTabEnum.AUTOTEST && (
            <Control
              showPreviewButton={tab === ExerciseTabEnum.THEORY}
              showCompleteButton={!isAdmin && tab === ExerciseTabEnum.TASK_CODE}
              showRetryButton={!isAdmin}
            />
          )}
        </Block>
      </Block>
    </ExerciseContext.Provider>
  );
});

export default Exercise;
