import React, { FC, memo, PropsWithChildren, ReactNode, useCallback } from 'react';
import styled from 'styled-components';

interface Props {
  onClick: VoidFunction;
}

const IconButton: FC<PropsWithChildren & Props> = memo(({ children, onClick }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    [onClick],
  );

  return <Wrapper onClick={handleClick}>{children}</Wrapper>;
});

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default IconButton;
