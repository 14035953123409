export const theme = {
  colors: {
    typography: '#566A7F',
    typography2: '#3D3F99',
    primary: '#696CFF',
    warning: '#F7542E',
  },
  fonts: {
    main: 'GothamPro, sans-serif',
  },
};
