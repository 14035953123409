export enum TypographyTextAlignEnum {
  START = 'start',
  END = 'end',
  CENTER = 'center',
  JUSTIFY = 'justify',
}

export enum TypographyVariantEnum {
  DEFAULT = 'default',
  ALTERNATIVE = 'alternative',
}

export enum TypographyWritingModeEnum {
  VERTICAL_RL = 'vertical-rl',
  VERTICAL_LR = 'vertical-lr',
}

export enum TypographyTextDecorationEnum {
  UNDERLINE = 'underline',
}

export enum EditorLanguageEnum {
  JAVASCRIPT = 'javascript',
  CSS = 'css',
  HTML = 'html',
}

export enum EditorThemeEnum {
  LIGHT = 'light',
  DARK = 'vs-dark',
}

export enum BlockFlexDirectionEnum {
  ROW = 'row',
  COLUMN = 'column',
}

export enum BlockAlignItemsEnum {
  CENTER = 'center',
}

export enum BlockJustifyContentEnum {
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
  SPACE_BETWEEN = 'space-between',
  CENTER = 'center',
}

export enum BlockFlexWrapEnum {
  WRAP = 'wrap',
}

export enum BlockFlexEnum {
  FLEX_0_0_AUTO = '0 0 auto',
  FLEX_1_1_AUTO = '1 1 auto',
}

export enum BlockOverflowYEnum {
  AUTO = 'auto',
  HIDDEN = 'hidden',
}

export enum InputTypeEnum {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
}

export enum ButtonTypeEnum {
  BUTTON = 'button',
  RESET = 'reset',
  SUBMIT = 'submit',
}

export enum ButtonVariantEnum {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  WARNING = 'warning',
}

export enum CardBorderTypeEnum {
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted',
}

export enum ModalsEnum {
  COURSE_MODAL = 'course_modal',
  MODULE_MODAL = 'module_modal',
  TOPIC_MODAL = 'topic_modal',
  EXERCISE_MODAL = 'exercise_modal',
  FILE_MODAL = 'file_modal',
  CONFIRMATION_MODAL = 'confirmation-modal',
  USER_MODAL = 'user_modal',
  LIST_TOPICS_EXERCISES = 'list_topics_exercises',
}

export enum ExerciseTabEnum {
  THEORY = 'theory',
  TASK = 'task',
  CODE = 'code',
  AUTOTEST = 'autotest',
  TOPICS_LIST = 'topics_list',
  TASK_CODE = 'task_code',
}

export enum ModalPositionEnum {
  RIGHT = 'right',
  CENTER = 'center',
}

export enum ExerciseUserStatus {
  UNOPENED = 'unopened',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}
