import React, { FC, memo } from 'react';

interface Props {
  rotate?: number;
}

const ArrowIcon: FC<Props> = memo(({ rotate = 0 }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        d='M7.41 15.4102L12 10.8302L16.59 15.4102L18 14.0002L12 8.00016L6 14.0002L7.41 15.4102Z'
        fill='#F7542E'
      />
    </svg>
  );
});

export default ArrowIcon;
