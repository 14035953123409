import React, { FC, memo, useContext, useMemo } from 'react';
import Card from '../../../components/card';
import Block from '../../../components/block';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  ExerciseUserStatus,
  ModalsEnum,
  TypographyVariantEnum,
} from '../../../config/enums';
import Typography from '../../../components/typography';
import Divider from '../../../components/divider';
import IconButton from '../../../components/iconButton';
import EditIcon from '../../../icons/Edit';
import Button from '../../../components/button';
import { ModalContext } from '../../../contexts/modal';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../../store/app';
import { disabledState } from '../../../config/consts';

interface Props {
  id: string;
  name: string;
  module_id: string;
  course_id: string;
  topic_id: string;
  user_status: string;
  state: string;
}

const ExerciseCard: FC<Props> = memo(
  ({ id, name, topic_id, module_id, course_id, user_status, state }) => {
    console.log(state);
    const { showModal } = useContext(ModalContext);
    const isAdmin = useApp((state) => state.isAdmin);

    const navigate = useNavigate();

    const { status, statusColor } = useMemo(() => {
      let status, statusColor;
      if (user_status === ExerciseUserStatus.COMPLETED) {
        status = 'Выполнено';
        statusColor = '#58C432';
      } else if (user_status === ExerciseUserStatus.UNOPENED) {
        status = 'Не выполнено';
        statusColor = '#F7542E';
      } else if (user_status === ExerciseUserStatus.IN_PROGRESS) {
        status = 'В работе';
        statusColor = '#566A7F';
      }
      return { status, statusColor };
    }, [user_status]);

    const isDisabled = useMemo(() => state === disabledState, [state]);

    return (
      <Card background={isDisabled && '#d0d0d0'}>
        <Block gap={10} flexDirection={BlockFlexDirectionEnum.COLUMN}>
          <Block
            gap={10}
            justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
            alignItems={BlockAlignItemsEnum.CENTER}
          >
            <Typography fontSize={18} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
              {name}
            </Typography>
            {isAdmin && (
              <IconButton
                onClick={() =>
                  showModal(ModalsEnum.EXERCISE_MODAL, { topic_id, module_id, course_id, id, name })
                }
              >
                <EditIcon />
              </IconButton>
            )}
          </Block>
          <Divider />
          <Block gap={10}>
            <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE}>
              Статус
            </Typography>
            <Typography
              fontSize={14}
              variant={TypographyVariantEnum.ALTERNATIVE}
              color={statusColor}
              bold
            >
              {status}
            </Typography>
          </Block>
          {(isAdmin || !isDisabled) && (
            <Button height={30} onClick={() => navigate(`${id}/theory`)}>
              Открыть задание
            </Button>
          )}
        </Block>
      </Card>
    );
  },
);

export default ExerciseCard;
