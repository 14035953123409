import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import Typography from '../typography';
import { TypographyVariantEnum } from '../../config/enums';
import { FormControlInterface } from '../../config/interfaces';

interface Props {
  label?: string;
  placeholder?: string;
}

const TextArea: FC<Props & FormControlInterface> = memo(
  ({ label, placeholder, value, onChange, error }) => {
    const handleChange = useCallback(
      (e) => {
        onChange(e.target.value);
      },
      [onChange],
    );

    return (
      <Wrapper>
        {label && (
          <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE}>
            {label}
          </Typography>
        )}
        <TextareaWrapper
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          error={!!error?.message}
        />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const TextareaWrapper = styled.textarea<{ error: boolean }>`
  height: 120px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.main};
  outline: none;
  border: 1px solid ${({ error }) => (error ? 'rgba(255, 0, 0, 0.5)' : 'rgba(51, 56, 60, 0.2)')};
  border-radius: 5px;
  resize: none;
`;

export default TextArea;
