import React, { FC, memo, ReactNode } from 'react';
import { AnchorPopup } from './Popup';
import { useAnchorPopup } from './useAnchorPopup';

export interface Props {
  children: ReactNode;
}

const AnchorPopupContext = React.createContext(undefined);

const ContentContainer: FC<Props> = memo(({ children }) => <>{children}</>);

const AnchorPopupProvider: FC<Props> = memo(({ children }) => {
  const { meta, showPopup, hidePopup, changeComponent } = useAnchorPopup();

  return (
    <AnchorPopupContext.Provider value={{ showPopup, hidePopup, changeComponent }}>
      <ContentContainer>{children}</ContentContainer>
      <AnchorPopup meta={meta} />
    </AnchorPopupContext.Provider>
  );
});

export { AnchorPopupProvider, AnchorPopupContext };
