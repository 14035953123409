import React, { FC, memo } from 'react';
import Block from '../block';
import { TabItemInterface } from '../../config/interfaces';
import { tabItemIdType } from './type';
import TabItem from './Item';
import { BlockFlexDirectionEnum } from '../../config/enums';

interface Props {
  list: TabItemInterface[];
  tab: any;
  onChange: (tab: any) => void;
}

const VerticalTabs: FC<Props> = memo(({ list, tab, onChange }) => {
  return (
    <Block gap={10} flexDirection={BlockFlexDirectionEnum.COLUMN}>
      {list.map((el) => (
        <TabItem key={`tab-${el.id}`} {...el} active={el.id === tab} onChange={onChange} />
      ))}
    </Block>
  );
});

export default VerticalTabs;
