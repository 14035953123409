import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  ModalsEnum,
  TypographyVariantEnum,
} from '../../../config/enums';
import Typography from '../../../components/typography';
import IconButton from '../../../components/iconButton';
import MoreIcon from '../../../icons/More';
import Block from '../../../components/block';
import styled from 'styled-components';
import { AnchorPopupContext } from '../../../contexts/anchorPopup';
import { ModalContext } from '../../../contexts/modal';
import { useExercise } from '../../../store/exercise';

interface Props {
  row: any;
  idx: number;
  setAutotestIndex: (index: number) => void;
}

const MoreBlock = memo(({ row, idx, setAutotestIndex }: Props) => {
  const componentId = useId();
  const { hidePopup, changeComponent, showPopup } = useContext(AnchorPopupContext);
  const { showModal } = useContext(ModalContext);
  const removeAutotest = useExercise((state) => state.removeAutotest);

  const [open, setOpen] = useState<boolean>(false);
  const isAnchor = useRef<boolean>(false);
  const timer = useRef<any>();
  const timeout = useRef<any>();
  const selectRef = useRef<any>();

  useEffect(() => {
    if (!open) {
      clearTimeout(timer.current);
      isAnchor.current = true;
      timer.current = setTimeout(() => {
        isAnchor.current = false;
      }, 200);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timeout.current);
    };
  }, []);

  const PopupComponent = useMemo(() => {
    return (
      <Box>
        <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={5}>
          <Block
            onClick={() => {
              setAutotestIndex(idx);
              hidePopup();
            }}
          >
            <Typography>Редактировать</Typography>
          </Block>
          <Block
            onClick={() => {
              showModal(ModalsEnum.CONFIRMATION_MODAL, {
                title: 'Удаление пользователя',
                description: `Вы действительно хотите удалить автотест?`,
                onClick: () => removeAutotest(idx),
              });
              hidePopup();
            }}
          >
            <Typography color={'#F7542E'}>Удалить</Typography>
          </Block>
        </Block>
      </Box>
    );
  }, [row]);

  useEffect(() => {
    changeComponent(PopupComponent, componentId);
  }, [PopupComponent, componentId]);

  const onClose = useCallback(() => {
    setOpen(false);
    hidePopup();
  }, []);

  const toggleOpen = useCallback(() => {
    if (!isAnchor.current) {
      setOpen((state) => {
        if (!state) {
          clearTimeout(timeout.current);
          timeout.current = setTimeout(() => {
            showPopup({
              component: PopupComponent,
              anchorRef: selectRef.current,
              onClose,
              componentId,
              width: '210px',
            });
          }, 0);
        }
        return !state;
      });
    }
  }, [PopupComponent, componentId, onClose]);

  return (
    <Block
      alignItems={BlockAlignItemsEnum.CENTER}
      justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
      gap={20}
    >
      <Typography variant={TypographyVariantEnum.ALTERNATIVE}>{row.name}</Typography>
      <IconWrapper ref={selectRef}>
        <IconButton onClick={toggleOpen}>
          <MoreIcon />
        </IconButton>
      </IconWrapper>
    </Block>
  );
});

const IconWrapper = styled.div`
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  right: 20px;
  padding: 10px;
  border-radius: 5px;
  background: white;
  border: 1px solid rgba(105, 108, 255, 0.3);
  z-index: 1;
`;

export default MoreBlock;
