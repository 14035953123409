import React, { FC, memo, useMemo } from 'react';
import Button from '../../../components/button';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  BlockFlexEnum,
  BlockJustifyContentEnum,
  ButtonVariantEnum,
  TypographyVariantEnum,
} from '../../../config/enums';
import Block from '../../../components/block';
import Typography from '../../../components/typography';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useExercise } from '../../../store/exercise';
import Table from '../../../components/table';
import MoreBlock from './MoreBlock';

interface Props {
  setAutotestIndex: (value: number) => void;
}

const AutotestList: FC<Props> = memo(({ setAutotestIndex }) => {
  const tests = useExercise((state) => state.meta.tests);

  const { moduleId } = useParams() as any;
  const navigate = useNavigate();

  const fields = useMemo(
    () => [
      {
        label: 'Название',
        handleField: (row, idx) => (
          <MoreBlock row={row} idx={idx} setAutotestIndex={setAutotestIndex} />
        ),
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <ButtonWrapper>
        <Button onClick={() => setAutotestIndex(-1)}>Добавить автотест</Button>
      </ButtonWrapper>
      <Block flex={BlockFlexEnum.FLEX_1_1_AUTO} flexDirection={BlockFlexDirectionEnum.COLUMN}>
        <Block padding={'0 0 0 20px'} flex={BlockFlexEnum.FLEX_0_0_AUTO}>
          <Typography fontSize={28} bold variant={TypographyVariantEnum.ALTERNATIVE}>
            Автотесты
          </Typography>
        </Block>
        <Table fields={fields} data={tests} />
      </Block>
      <Block
        height={'60px'}
        justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
        alignItems={BlockAlignItemsEnum.CENTER}
        background={'#f5f5f9'}
        flex={BlockFlexEnum.FLEX_0_0_AUTO}
      >
        <Block></Block>
        <Block gap={15}>
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            onClick={() => navigate(`/module/${moduleId}`)}
          >
            Отменить
          </Button>
        </Block>
      </Block>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  background: #f5f5f9;
  overflow: hidden;
  padding: 30px 30px 0 10px;
  gap: 30px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
`;

export default AutotestList;
