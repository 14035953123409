const STORAGE = localStorage;
const TOKEN_KEY = 'access_token';

export default class Storage {
  static setToken(value: string) {
    this.set(TOKEN_KEY, value);
  }

  static getToken() {
    return this.get(TOKEN_KEY);
  }

  static removeToken() {
    this.remove(TOKEN_KEY);
  }

  static set(name: string, value: string) {
    STORAGE.setItem(name, value);
  }

  static get(name: string) {
    return STORAGE.getItem(name);
  }

  static remove(name: string) {
    STORAGE.removeItem(name);
  }

  static clear() {
    STORAGE.clear();
  }
}
