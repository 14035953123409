import React, { FC, memo } from 'react';
import { TableFieldsType } from '../../../config/interfaces';
import styled from 'styled-components';
import Typography from '../../typography';
import { BlockAlignItemsEnum, TypographyVariantEnum } from '../../../config/enums';
import Block from '../../block';

interface Props {
  fields: TableFieldsType[];
}

const TableHead: FC<Props> = memo(({ fields }) => {
  return (
    <THeadRow>
      {fields.map((el, idx) => (
        <THeadCell key={`field-${idx}`}>
          <Block alignItems={BlockAlignItemsEnum.CENTER} height={'50px'}>
            <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
              {el.label}
            </Typography>
          </Block>
        </THeadCell>
      ))}
    </THeadRow>
  );
});

const THeadRow = styled.tr`
  height: 50px;
  border-bottom: 1px solid rgba(175, 175, 175, 0.25);
`;

const THeadCell = styled.th`
  vertical-align: center;
`;

export default TableHead;
