import React, { FC, memo } from 'react';
import Block from '../../components/block';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  TypographyVariantEnum,
} from '../../config/enums';
import noDataImage from '../../assets/images/no_data_modules.png';
import styled from 'styled-components';
import Divider from '../../components/divider';
import Typography from '../../components/typography';

const NoData: FC = memo(() => {
  return (
    <Block
      alignItems={BlockAlignItemsEnum.CENTER}
      justifyContent={BlockJustifyContentEnum.CENTER}
      height={'100%'}
    >
      <Block
        flexDirection={BlockFlexDirectionEnum.COLUMN}
        alignItems={BlockAlignItemsEnum.CENTER}
        justifyContent={BlockJustifyContentEnum.CENTER}
        gap={10}
        width={'30%'}
      >
        <Img src={noDataImage} alt={'no data'} />
        <Divider />
        <Block
          gap={5}
          flexDirection={BlockFlexDirectionEnum.COLUMN}
          alignItems={BlockAlignItemsEnum.CENTER}
        >
          <Typography variant={TypographyVariantEnum.ALTERNATIVE} fontSize={14}>
            Сейчас нет ни одной программы
          </Typography>
          <Typography variant={TypographyVariantEnum.ALTERNATIVE} fontSize={14}>
            Создайте новую прямо сейчас!
          </Typography>
        </Block>
      </Block>
    </Block>
  );
});

const Wrapper = styled.div`
  width: 40%;
`;

const Img = styled.img`
  width: 100%;
`;

export default NoData;
