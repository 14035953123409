import React, { FC, memo } from 'react';
import styled from 'styled-components';

interface Props {
  color?: string;
}

const Divider: FC<Props> = memo(({ color = '#696cff' }) => {
  return <Wrapper background={color} />;
});

const Wrapper = styled.div<{ background: string }>`
  height: 1px;
  background: ${({ background }) => background};
  width: 100%;
`;

export default Divider;
