import React, { FC, memo } from 'react';

const UserIcon: FC = memo(() => {
  return (
    <svg width='28' height='31' viewBox='0 0 28 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.6433 15.1986C20.0737 14.1831 21.1489 12.7285 21.7124 11.0468C22.2759 9.36499 22.2983 7.54373 21.7763 5.84807C21.2544 4.1524 20.2153 2.67084 18.8104 1.61902C17.4054 0.567189 15.7079 0 13.9649 0C12.2219 0 10.5244 0.567189 9.11946 1.61902C7.71451 2.67084 6.67544 4.1524 6.15349 5.84807C5.63153 7.54373 5.65394 9.36499 6.21745 11.0468C6.78096 12.7285 7.85616 14.1831 9.28655 15.1986C6.70684 16.2782 4.47225 18.0677 2.83236 20.3674C1.19247 22.667 0.211841 25.3863 0 28.2213C0 28.2213 2.26901 31 13.9649 31C25.6608 31 28 28.3644 28 28.3644C27.8074 25.4963 26.828 22.7411 25.1736 20.4132C23.5192 18.0853 21.2564 16.2784 18.6433 15.1986ZM8.11696 8.34142C8.11696 7.16209 8.45994 6.00924 9.10252 5.02867C9.7451 4.04809 10.6584 3.28383 11.727 2.83252C12.7956 2.38121 13.9714 2.26313 15.1058 2.4932C16.2402 2.72328 17.2822 3.29118 18.1 4.12509C18.9179 4.959 19.4749 6.02147 19.7005 7.17813C19.9261 8.3348 19.8103 9.53372 19.3677 10.6233C18.9251 11.7128 18.1756 12.6441 17.2139 13.2993C16.2522 13.9545 15.1215 14.3042 13.9649 14.3042C12.4139 14.3042 10.9265 13.676 9.82979 12.5577C8.73308 11.4395 8.11696 9.92284 8.11696 8.34142ZM2.53801 27.0049C3.7076 21.0421 8.38597 16.6893 13.9649 16.6893C19.5439 16.6893 24.3041 21.066 25.4152 27.1003C23.9883 27.6728 20.7368 28.5553 13.9766 28.5553C7.21637 28.5553 3.92982 27.6131 2.53801 27.0049Z'
        fill='#696CFF'
      />
    </svg>
  );
});

export default UserIcon;
