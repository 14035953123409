import React, { memo, useContext, useMemo } from 'react';
import Card from '../../../components/card';
import Block from '../../../components/block';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  ModalsEnum,
  TypographyVariantEnum,
} from '../../../config/enums';
import Typography from '../../../components/typography';
import IconButton from '../../../components/iconButton';
import EditIcon from '../../../icons/Edit';
import Divider from '../../../components/divider';
import Button from '../../../components/button';
import Progress from '../../../components/progress';
import { ModalContext } from '../../../contexts/modal';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../../store/app';
import { useQuery } from 'react-query';
import { queries } from '../../../config/queries';
import { fetchInstance } from '../../../utils/axios';
import { api } from '../../../config/api';

interface Props {
  id: string;
  name: string;
  description?: string;
  homeworks_count?: number;
  theoretical_count?: number;
  topics_count?: number;
  course_id: string;
  progress?: {
    exercises_count: number;
    user_progress: number;
  };
}

const ModuleCard = memo(
  ({
    id,
    name,
    description,
    homeworks_count,
    theoretical_count,
    topics_count,
    course_id,
    progress: progressProp,
  }: Props) => {
    const { showModal } = useContext(ModalContext);
    const isAdmin = useApp((state) => state.isAdmin);

    const navigate = useNavigate();

    const progress = useMemo(() => {
      if (progressProp && progressProp?.exercises_count) {
        return Math.floor((progressProp.user_progress / progressProp.exercises_count) * 100);
      }
      return 0;
    }, [progressProp]);

    return (
      <Card>
        <Block
          flexDirection={BlockFlexDirectionEnum.COLUMN}
          gap={15}
          justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
          height={'100%'}
        >
          <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={10}>
            <Block
              flexDirection={BlockFlexDirectionEnum.ROW}
              alignItems={BlockAlignItemsEnum.CENTER}
              justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
            >
              <Typography fontSize={18} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
                {name}
              </Typography>
              {isAdmin && (
                <IconButton
                  onClick={() =>
                    showModal(ModalsEnum.MODULE_MODAL, {
                      id,
                      name,
                      description,
                      homeworks_count,
                      theoretical_count,
                      topics_count,
                      course_id,
                    })
                  }
                >
                  <EditIcon />
                </IconButton>
              )}
            </Block>
            <Divider />
            <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE}>
              {description}
            </Typography>
            <Block
              flexDirection={BlockFlexDirectionEnum.ROW}
              gap={10}
              alignItems={BlockAlignItemsEnum.CENTER}
              justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
            >
              <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
                Ключевые темы
              </Typography>
              <Typography fontSize={20} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
                {topics_count}
              </Typography>
            </Block>
            <Block
              flexDirection={BlockFlexDirectionEnum.ROW}
              gap={10}
              alignItems={BlockAlignItemsEnum.CENTER}
              justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
            >
              <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
                Теоретические занятия
              </Typography>
              <Typography fontSize={20} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
                {theoretical_count}
              </Typography>
            </Block>
            <Block
              flexDirection={BlockFlexDirectionEnum.ROW}
              gap={10}
              alignItems={BlockAlignItemsEnum.CENTER}
              justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
            >
              <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
                Практические домашние занятия
              </Typography>
              <Typography fontSize={20} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
                {homeworks_count}
              </Typography>
            </Block>
          </Block>
          <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={15}>
            {!isAdmin && <Progress progress={progress} />}
            <Button height={30} onClick={() => navigate(`/module/${id}`)}>
              Открыть тренажер
            </Button>
          </Block>
        </Block>
      </Card>
    );
  },
);

export default ModuleCard;
