import React, { FC, memo, useContext, useMemo } from 'react';
import Block from '../../../components/block';
import { BlockAlignItemsEnum, BlockFlexDirectionEnum, ModalsEnum } from '../../../config/enums';
import Typography from '../../../components/typography';
import IconButton from '../../../components/iconButton';
import EditIcon from '../../../icons/Edit';
import { ModalContext } from '../../../contexts/modal';
import Grid from '../../../components/grid';
import AddCard from '../../../components/addCard';
import ExerciseCard from './exerciseCard';
import { getItemsInRow } from '../../../utils/getItemsInRow';
import { useApp } from '../../../store/app';
import orderBy from 'lodash/orderBy';
import { useQuery } from 'react-query';
import { queries } from '../../../config/queries';
import { fetchInstance } from '../../../utils/axios';
import { api } from '../../../config/api';
import Loader from '../../../components/loader';
import { MAX_LIMIT } from '../../../config/consts';

interface Props {
  id: string;
  name: string;
  module_id: string;
  course_id: string;
}

const TopicItem: FC<Props> = memo(({ name, course_id, module_id, id }) => {
  const { showModal } = useContext(ModalContext);
  const isAdmin = useApp((state) => state.isAdmin);

  const { data, isLoading } = useQuery(
    [queries.exercisesListByTopic(id)],
    () =>
      fetchInstance({
        method: 'POST',
        url: api.exercisesSearch,
        data: {
          limit: MAX_LIMIT,
          filter: [
            {
              column: 'topic_id',
              value: id,
            },
          ],
          with: {
            properties: ['user_status'],
          },
        },
      }),
    {
      cacheTime: 0,
      onSuccess: (response) => {
        if (isAdmin) {
          const exercises = orderBy(response.data, ['name']).map((el) => el.id);
          if (exercises.length) {
            fetchInstance({
              method: 'POST',
              url: api.exercisesSetPosition,
              data: {
                ids: exercises,
              },
            });
          }
        }
      },
    },
  );

  const exercises = useMemo(() => orderBy(data?.data || [], ['name']), [data]);

  return (
    <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={25}>
      <Block gap={10} alignItems={BlockAlignItemsEnum.CENTER}>
        <Typography>{name}</Typography>
        {isAdmin && (
          <IconButton
            onClick={() => showModal(ModalsEnum.TOPIC_MODAL, { course_id, module_id, id, name })}
          >
            <EditIcon />
          </IconButton>
        )}
      </Block>
      <Grid itemsInRow={getItemsInRow()} gap={25}>
        {isLoading ? <Loader /> : exercises.map((el) => <ExerciseCard key={el.id} {...el} />)}
        {isAdmin && (
          <AddCard
            onClick={() =>
              showModal(ModalsEnum.EXERCISE_MODAL, { course_id, module_id, topic_id: id })
            }
          />
        )}
      </Grid>
    </Block>
  );
});

export default TopicItem;
