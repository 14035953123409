import React, { FC, memo, PropsWithChildren } from 'react';
import styled from 'styled-components';
import Typography from '../typography';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { ModalPositionEnum, TypographyVariantEnum } from '../../config/enums';
import { ModalCommon } from '../../config/interfaces';

interface Props {
  title?: string;
  position?: ModalPositionEnum;
}

const Modal: FC<Props & PropsWithChildren & ModalCommon> = memo(
  ({ title, handleHide, children, position = ModalPositionEnum.RIGHT }) => {
    const ref = useOutsideClick(handleHide);

    return (
      <>
        <ModalBackground />
        <Wrapper ref={ref} position={position}>
          {title && (
            <TitleWrapper>
              <Typography fontSize={20} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
                {title}
              </Typography>
            </TitleWrapper>
          )}
          <Content>{children}</Content>
        </Wrapper>
      </>
    );
  },
);

const Wrapper = styled.div<{ position: ModalPositionEnum }>`
  position: absolute;
  width: 560px;
  background: #f5f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${({ position }) => {
    if (position === ModalPositionEnum.RIGHT) {
      return {
        top: 0,
        right: 0,
        height: '100%',
        padding: '70px 30px 10px 30px',
      };
    } else if (position === ModalPositionEnum.CENTER) {
      return {
        padding: '25px 20px',
        borderRadius: '5px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };
    }
  }}
`;

const TitleWrapper = styled.div`
  margin-bottom: 30px;
`;

const Content = styled.div`
  height: 100%;
`;

const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
`;

export default Modal;
