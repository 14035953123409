import React, { FC, memo, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { queries } from '../../config/queries';
import { fetchInstance } from '../../utils/axios';
import { api } from '../../config/api';
import styled from 'styled-components';
import Button from '../../components/button';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  ModalsEnum,
  TypographyVariantEnum,
} from '../../config/enums';
import Loader from '../../components/loader';
import Block from '../../components/block';
import Typography from '../../components/typography';
import TopicItem from './topicItem';
import IconButton from '../../components/iconButton';
import EditIcon from '../../icons/Edit';
import { ModalContext } from '../../contexts/modal';
import { useApp } from '../../store/app';
import orderBy from 'lodash/orderBy';

const Modules: FC = memo(() => {
  const setBreadcrumbs = useApp((state) => state.setBreadcrumbs);
  const isAdmin = useApp((state) => state.isAdmin);
  const navigate = useNavigate();
  const { showModal } = useContext(ModalContext);
  const { moduleId } = useParams() as any;

  const { data, isLoading } = useQuery(
    [queries.moduleDetails],
    () =>
      fetchInstance({
        method: 'GET',
        url: api.modulesCRUD(moduleId),
      }),
    {
      cacheTime: 0,
      onSuccess: (response) => {
        if (isAdmin) {
          const topics = response.data.topics?.map((el) => el.id);
          if (topics.length) {
            fetchInstance({
              method: 'POST',
              url: api.topicsSetPosition,
              data: {
                ids: topics,
              },
            });
          }
        }
      },
    },
  ) as any;

  const meta = useMemo(() => data?.data, [data]);

  useEffect(() => {
    if (meta) {
      setBreadcrumbs([
        { label: 'Программы', action: () => navigate('/') },
        {
          label: meta.name,
          active: true,
        },
      ]);
    }
  }, [meta]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    return (
      <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={25}>
        <Block gap={10} alignItems={BlockAlignItemsEnum.CENTER}>
          <Typography variant={TypographyVariantEnum.ALTERNATIVE} fontSize={20} bold={true}>
            {meta.name}
          </Typography>
          {isAdmin && (
            <IconButton onClick={() => showModal(ModalsEnum.MODULE_MODAL, { ...meta })}>
              <EditIcon />
            </IconButton>
          )}
        </Block>
        {orderBy(meta?.topics || [], ['name']).map((el) => (
          <TopicItem key={el.id} {...el} />
        ))}
      </Block>
    );
  }, [isLoading, meta]);

  return (
    <Wrapper>
      {isAdmin && (
        <ButtonWrapper>
          <Button
            onClick={() =>
              showModal(ModalsEnum.TOPIC_MODAL, { course_id: meta.course_id, module_id: moduleId })
            }
            disabled={!meta}
          >
            Добавить тему
          </Button>
        </ButtonWrapper>
      )}
      <Container>{content}</Container>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  background: #f5f5f9;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  gap: 50px;
  height: 100%;
  overflow-y: auto;
`;

export default Modules;
