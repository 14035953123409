import React, { FC, memo } from 'react';

const EyeIcon: FC = memo(() => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 4.5C11.8425 4.5 14.3775 6.0975 15.615 8.625C14.3775 11.1525 11.8425 12.75 9 12.75C6.1575 12.75 3.6225 11.1525 2.385 8.625C3.6225 6.0975 6.1575 4.5 9 4.5ZM9 3C5.52948 3 2.52788 4.99779 1.07128 7.90543C0.844422 8.35826 0.844422 8.89174 1.07128 9.34457C2.52788 12.2522 5.52948 14.25 9 14.25C12.4705 14.25 15.4721 12.2522 16.9287 9.34457C17.1556 8.89174 17.1556 8.35826 16.9287 7.90543C15.4721 4.99779 12.4705 3 9 3ZM9 6.75C10.035 6.75 10.875 7.59 10.875 8.625C10.875 9.66 10.035 10.5 9 10.5C7.965 10.5 7.125 9.66 7.125 8.625C7.125 7.59 7.965 6.75 9 6.75ZM9 5.25C7.14 5.25 5.625 6.765 5.625 8.625C5.625 10.485 7.14 12 9 12C10.86 12 12.375 10.485 12.375 8.625C12.375 6.765 10.86 5.25 9 5.25Z'
        fill='#3D3F99'
        fillOpacity='0.5'
      />
    </svg>
  );
});

export default EyeIcon;
