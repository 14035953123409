import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import loginLeftSide from '../../assets/images/login_left_side.png';
import loginRightSide from '../../assets/images/login_right_side.png';
import loginLogo from '../../assets/images/login_logo.png';
import Typography from '../../components/typography';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  ButtonTypeEnum,
  InputTypeEnum,
  TypographyVariantEnum,
} from '../../config/enums';
import Input from '../../components/input';
import Button from '../../components/button';
import Block from '../../components/block';
import { useMutation } from 'react-query';
import { fetchInstance, setToken } from '../../utils/axios';
import { api } from '../../config/api';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Storage from '../../utils/storage';
import { useApp } from '../../store/app';
import { Form } from '../../styles/components';

const schema = yup.object().shape({
  login: yup.string().required(),
  password: yup.string().required(),
});

const Login: FC = memo(() => {
  const setProfile = useApp((state) => state.setProfile);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const mutation = useMutation(
    (data) =>
      fetchInstance({
        method: 'POST',
        url: api.authLogin,
        data,
      }),
    {
      onSuccess: (response) => {
        Storage.setToken(response.data.token);
        setToken(response.data.token);
        setProfile(response.data.profile);
      },
    },
  );

  const onSubmit = useCallback(
    (data) => {
      mutation.mutate(data);
    },
    [mutation],
  );

  return (
    <Wrapper>
      <LeftImage src={loginLeftSide} />
      <RightImage src={loginRightSide} />
      <Box>
        <Block
          gap={5}
          flexDirection={BlockFlexDirectionEnum.COLUMN}
          alignItems={BlockAlignItemsEnum.CENTER}
        >
          <Logo src={loginLogo} alt='logo' />
          <Typography fontSize={20} variant={TypographyVariantEnum.ALTERNATIVE}>
            Тренажёр MedIT
          </Typography>
          <Typography fontSize={14} color={'rgba(61, 63, 153, 0.50)'}>
            Цифровая кафедра МГМСУ
          </Typography>
        </Block>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Block gap={25} flexDirection={BlockFlexDirectionEnum.COLUMN} width={'100%'}>
            <Controller
              render={({ field, fieldState }) => (
                <Input {...field} {...fieldState} label={'Логин'} placeholder={'Введите логин'} />
              )}
              name={'login'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Пароль'}
                  type={InputTypeEnum.PASSWORD}
                  placeholder={'Введите пароль'}
                />
              )}
              name={'password'}
              control={control}
            />
          </Block>
          <ButtonWrapper>
            <Button width={'100%'} type={ButtonTypeEnum.SUBMIT}>
              Войти
            </Button>
          </ButtonWrapper>
        </Form>
      </Box>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #f5f5f9;
`;

const BackgroundImage = styled.img`
  position: absolute;
  height: 90%;
  top: 5%;
`;

const LeftImage = styled(BackgroundImage)`
  left: 0;
`;

const RightImage = styled(BackgroundImage)`
  right: 0;
`;

const Logo = styled.img`
  width: 60px;
`;

const Box = styled.div`
  width: 376px;
  height: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #fefeff;
  border-radius: 10px;
  padding: 25px 25px 45px 25px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  gap: 35px;
`;

const ButtonWrapper = styled.div`
  margin-top: 50px;
`;

export default Login;
