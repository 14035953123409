import React, { FC, memo } from 'react';

const MoreIcon = memo(() => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='3'
        cy='3'
        r='3'
        transform='matrix(1 0 0 -1 7 23)'
        fill='#AFAFAF'
        fillOpacity='0.5'
      />
      <circle
        cx='3'
        cy='3'
        r='3'
        transform='matrix(1 0 0 -1 17 23)'
        fill='#AFAFAF'
        fillOpacity='0.5'
      />
      <circle
        cx='3'
        cy='3'
        r='3'
        transform='matrix(1 0 0 -1 27 23)'
        fill='#AFAFAF'
        fillOpacity='0.5'
      />
    </svg>
  );
});

export default MoreIcon;
