import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { TableFieldsType } from '../../../config/interfaces';

interface Props {
  row: any;
  fields: TableFieldsType[];
  rowIndex?: number;
}

const TableBodyRow: FC<Props> = memo(({ row, fields, rowIndex }) => {
  return (
    <>
      {fields.map((el: any, idx) => {
        let content = '';
        if (el.handleField) {
          content = el.handleField(row, rowIndex);
        } else if (el.field) {
          if (el.options) {
            content = el.options[row[el.field]] || row[el.field];
          } else {
            content = row[el.field];
          }
        }
        return <TBodyCell key={`${el.id}_${idx}`}>{content}</TBodyCell>;
      })}
    </>
  );
});

const TBodyCell = styled.td``;

export default TableBodyRow;
