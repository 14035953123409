import { useCallback, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

export const useModal = () => {
  const [modals, setModals] = useState({});

  const showModal = useCallback((modalName, params = {}) => {
    setModals((state) => ({
      ...state,
      [modalName]: params,
    }));
  }, []);

  const hideModal = useCallback((modalName) => {
    setModals((state) => {
      const newState = cloneDeep(state);
      delete newState[modalName];
      return newState;
    });
  }, []);

  const hideAllModals = useCallback(() => {
    setModals({});
  }, []);

  return { modals, showModal, hideModal, hideAllModals };
};
