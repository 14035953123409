import React, { FC, memo } from 'react';

const CheckIcon: FC = memo(() => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 18.5L19 25.5L29 10' stroke='white' strokeWidth='3' strokeLinecap='round' />
    </svg>
  );
});

export default CheckIcon;
