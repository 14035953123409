import React, { FC, memo } from 'react';
import MonacoEditor from '@monaco-editor/react';
import { EditorLanguageEnum, EditorThemeEnum } from '../../config/enums';

interface Props {
  language: EditorLanguageEnum;
  theme?: EditorThemeEnum;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const Editor: FC<Props> = memo(
  ({ language, theme = EditorThemeEnum.LIGHT, value, onChange, disabled = false }) => {
    return (
      <MonacoEditor
        height='100%'
        defaultLanguage={language}
        theme={theme}
        value={value}
        onChange={onChange}
        options={{
          minimap: {
            enabled: false,
          },
          acceptSuggestionOnCommitCharacter: true,
          acceptSuggestionOnEnter: 'on',
          accessibilitySupport: 'auto',
          // @ts-ignore
          autoIndent: false,
          automaticLayout: true,
          codeLens: true,
          colorDecorators: true,
          contextmenu: true,
          cursorBlinking: 'blink',
          // @ts-ignore
          cursorSmoothCaretAnimation: false,
          cursorStyle: 'line',
          disableLayerHinting: false,
          disableMonospaceOptimizations: false,
          dragAndDrop: false,
          fixedOverflowWidgets: false,
          folding: true,
          foldingStrategy: 'auto',
          fontLigatures: false,
          formatOnPaste: false,
          formatOnType: false,
          hideCursorInOverviewRuler: false,
          highlightActiveIndentGuide: true,
          links: true,
          mouseWheelZoom: false,
          multiCursorMergeOverlapping: true,
          multiCursorModifier: 'alt',
          overviewRulerBorder: true,
          overviewRulerLanes: 2,
          quickSuggestions: true,
          quickSuggestionsDelay: 100,
          readOnly: disabled,
          renderControlCharacters: false,
          // @ts-ignore
          renderFinalNewline: true,
          renderIndentGuides: true,
          renderLineHighlight: 'all',
          renderWhitespace: 'none',
          revealHorizontalRightPadding: 30,
          roundedSelection: true,
          rulers: [],
          scrollBeyondLastColumn: 5,
          scrollBeyondLastLine: true,
          selectOnLineNumbers: true,
          selectionClipboard: true,
          selectionHighlight: true,
          showFoldingControls: 'mouseover',
          smoothScrolling: false,
          suggestOnTriggerCharacters: true,
          wordBasedSuggestions: true,
          wordSeparators: '~!@#$%^&*()-=+[{]}|;:\'",.<>/?',
          wordWrap: 'off',
          wordWrapBreakAfterCharacters: '\t})]?|&,;',
          wordWrapBreakBeforeCharacters: '{([+',
          wordWrapBreakObtrusiveCharacters: '.',
          wordWrapColumn: 80,
          wordWrapMinified: true,
          wrappingIndent: 'none',
        }}
      />
    );
  },
);

export default Editor;
