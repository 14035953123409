import React, { FC, memo, PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';
import { CardBorderTypeEnum } from '../../config/enums';

interface Props {
  borderType?: CardBorderTypeEnum;
  onClick?: VoidFunction;
  isTransparent?: boolean;
  background?: string;
}

const Card: FC<PropsWithChildren & Props> = memo(
  ({
    children,
    borderType = CardBorderTypeEnum.SOLID,
    onClick,
    isTransparent = false,
    background = '#fefeff',
  }) => {
    const clickable = useMemo(() => !!onClick, [onClick]);

    return (
      <Wrapper
        borderType={borderType}
        onClick={onClick}
        clickable={clickable}
        isTransparent={isTransparent}
        background={background}
      >
        {children}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{
  borderType: CardBorderTypeEnum;
  clickable: boolean;
  isTransparent: boolean;
  background: string;
}>`
  background: ${({ isTransparent, background }) => (isTransparent ? 'transparent' : background)};
  padding: 20px 10px;
  border-radius: 5px;
  border: 2px ${({ borderType }) => borderType} rgba(105, 108, 255, 0.6);
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;
export default Card;
