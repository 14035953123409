import { ExerciseTabEnum, ModalsEnum } from './enums';

export const exerciseTabsListAdmin = [
  { id: ExerciseTabEnum.THEORY, label: 'Теория', link: 'theory' },
  { id: ExerciseTabEnum.TASK, label: 'Задача', link: 'task' },
  { id: ExerciseTabEnum.CODE, label: 'Код', link: 'code' },
  { id: ExerciseTabEnum.AUTOTEST, label: 'Автотесты', link: 'autotest' },
];

export const exerciseTabsListUser = (showModal) => [
  {
    id: ExerciseTabEnum.TOPICS_LIST,
    label: 'Список тем и занятий',
    action: () => showModal(ModalsEnum.LIST_TOPICS_EXERCISES),
  },
  { id: ExerciseTabEnum.THEORY, label: 'Теория', link: 'theory' },
  { id: ExerciseTabEnum.TASK_CODE, label: 'Задача и код', link: 'code' },
];

export const ROLES = {
  user: 'Пользователь',
  admin: 'Администратор',
};

export const MAX_LIMIT = 300;

export const defaultFileName = 'index.html';

export const leftMenuEditorId = 'left_menu_editor_id';

export const autotest_count = '__AUTOTEST_COUNT_CONST__';
export const autotest_test = '__AUTOTEST_TEST_ARRAY__';
export const start_autotest = '__START_AUTOTEST__';

export const defaultHtmlTemplate =
  '<html>\n' +
  '  <head>\n' +
  '    <title>HTML Sample</title>\n' +
  '    <meta http-equiv="X-UA-Compatible" content="IE=edge">\n' +
  '  </head>\n' +
  '  <body>\n' +
  '  </body>\n' +
  '</html>';

export const disabledState = 'disabled';
