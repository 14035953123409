import React, { memo, useCallback, useContext } from 'react';
import { connectModal } from '../contexts/modal/connectModal';
import {
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  BlockOverflowYEnum,
  ButtonTypeEnum,
  ButtonVariantEnum,
  InputTypeEnum,
  ModalsEnum,
} from '../config/enums';
import Modal from '../components/modal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { fetchInstance } from '../utils/axios';
import { api } from '../config/api';
import { queries } from '../config/queries';
import { toast } from 'react-toastify';
import { Form } from '../styles/components';
import Block from '../components/block';
import Button from '../components/button';
import Input from '../components/input';
import TextArea from '../components/textarea';
import { ModalContext } from '../contexts/modal';

const schema = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required(),
  description: yup.string().nullable(),
  topics_count: yup.number().required().min(0),
  theoretical_count: yup.number().required().min(0),
  homeworks_count: yup.number().required().min(0),
  course_id: yup.string().required(),
});

const ModuleModal = memo(({ handleHide, ...props }: any) => {
  const { showModal } = useContext(ModalContext);
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: props.id || '',
      name: props.name || '',
      description: props.description || '',
      topics_count: props.topics_count || 0,
      theoretical_count: props.theoretical_count || 0,
      homeworks_count: props.homeworks_count || 0,
      course_id: props.course_id,
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: any) =>
      fetchInstance({
        method: data.id ? 'PUT' : 'POST',
        url: api.modulesCRUD(data.id),
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.coursesList);
        queryClient.invalidateQueries(queries.modulesListByCourse(props.course_id));
        handleHide();
        toast.success('Модуль успешно сохранен!');
      },
    },
  );

  const removeMutation = useMutation(
    () =>
      fetchInstance({
        method: 'DELETE',
        url: api.modulesCRUD(props.id),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.coursesList);
        queryClient.invalidateQueries(queries.modulesListByCourse(props.course_id));

        handleHide();
        toast.success('Модуль удален!');
      },
    },
  );

  const onSubmit = useCallback(
    (data) => {
      mutation.mutate({ ...data, label: ['string'] });
    },
    [mutation],
  );

  return (
    <Modal title={`${props.id ? 'Редактирование' : 'Добавление'} модуля`} handleHide={handleHide}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Block
          flexDirection={BlockFlexDirectionEnum.COLUMN}
          justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
          height={'100%'}
          overflowY={BlockOverflowYEnum.AUTO}
        >
          <Block gap={30} flexDirection={BlockFlexDirectionEnum.COLUMN}>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Название'}
                  placeholder={'Введите название'}
                />
              )}
              name={'name'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <TextArea
                  {...field}
                  {...fieldState}
                  label={'Описание'}
                  placeholder={'Введите описание'}
                />
              )}
              name={'description'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Ключевые темы'}
                  placeholder={'Введите количество'}
                  type={InputTypeEnum.NUMBER}
                />
              )}
              name={'topics_count'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Теоретические занятия'}
                  placeholder={'Введите количество'}
                  type={InputTypeEnum.NUMBER}
                />
              )}
              name={'theoretical_count'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Практические домашние задания'}
                  placeholder={'Введите количество'}
                  type={InputTypeEnum.NUMBER}
                />
              )}
              name={'homeworks_count'}
              control={control}
            />
          </Block>

          <Block
            gap={15}
            justifyContent={
              props.id ? BlockJustifyContentEnum.SPACE_BETWEEN : BlockJustifyContentEnum.FLEX_END
            }
          >
            {props.id && (
              <Button
                variant={ButtonVariantEnum.WARNING}
                onClick={() => {
                  showModal(ModalsEnum.CONFIRMATION_MODAL, {
                    title: 'Удаление модуля',
                    description: `Вы действительно хотите удалить модуль?`,
                    onClick: () => removeMutation.mutate(),
                  });
                }}
              >
                Удалить
              </Button>
            )}
            <Block gap={15}>
              <Button width={'auto'} variant={ButtonVariantEnum.OUTLINED} onClick={handleHide}>
                Отменить
              </Button>
              <Button width={'auto'} type={ButtonTypeEnum.SUBMIT} disabled={mutation.isLoading}>
                Сохранить
              </Button>
            </Block>
          </Block>
        </Block>
      </Form>
    </Modal>
  );
});

export default connectModal(ModalsEnum.MODULE_MODAL)(ModuleModal);
