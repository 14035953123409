import React, { FC, memo, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  itemsInRow: number;
  gap?: number;
}

const Grid: FC<Props & PropsWithChildren> = memo(({ itemsInRow, gap = 0, children }) => {
  return (
    <Wrapper itemsInRow={itemsInRow} gap={gap}>
      {children}
    </Wrapper>
  );
});

const Wrapper = styled.div<{ itemsInRow: number; gap: number }>`
  display: grid;
  grid-template-columns: repeat(${({ itemsInRow }) => itemsInRow}, 1fr);
  grid-column-gap: ${({ gap }) => gap}px;
  grid-row-gap: ${({ gap }) => gap}px;
`;

export default Grid;
