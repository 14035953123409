import React, { FC, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AnchorPopupContext } from './index';
import styled, { css } from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';

const horizontalOffset = 20;

export enum HorizontalAlign {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface Props {
  horizontalAlign?: HorizontalAlign;
  meta: any;
}

const getAlign = (align) => {
  switch (align) {
    case HorizontalAlign.LEFT:
      return 'left';
    case HorizontalAlign.RIGHT:
      return 'right';
    default:
      return 'left';
  }
};

const PopupContent: FC<Props> = memo(({ meta, horizontalAlign }) => {
  const { hidePopup } = useContext(AnchorPopupContext);
  const { onClose, position: metaPosition, anchorRef, width, component } = meta;
  const timer = useRef<any>();

  const ref = useOutsideClick(() => {
    onClose && onClose();
    hidePopup();
  });

  const [position, setPosition] = useState(null);

  const hAllign = metaPosition || getAlign(horizontalAlign);

  const anchorBox = anchorRef?.getBoundingClientRect();

  const calculatePosition = useCallback(() => {
    if (ref.current) {
      if (anchorRef) {
        const box = ref.current.getBoundingClientRect();
        if (box.width > 0) {
          const parentBox = anchorRef.getBoundingClientRect();
          let topPosition, horizontal;
          if (parentBox.top + parentBox.height + box.height < document.body.clientHeight) {
            topPosition = parentBox.top + parentBox.height;
          } else {
            topPosition = parentBox.top - box.height;
          }

          if (box.height >= document.body.clientHeight) {
            ref.current.style.overflowY = 'scroll';
            ref.current.style.maxHeight = document.body.clientHeight + 'px';
          } else {
            // ref.current.style.overflowY = 'hidden';
          }
          if (topPosition < 0) {
            topPosition = 0;
          }
          if (topPosition + box.height > document.body.clientHeight) {
            topPosition = document.body.clientHeight - box.height;
          }

          if (hAllign === 'left') {
            if (parentBox.left + box.width < window.innerWidth) {
              horizontal = parentBox.left;
            } else {
              horizontal = parentBox.left + parentBox.width - box.width;
            }
          } else if (hAllign === 'right') {
            if (parentBox.right - box.width > 0) {
              horizontal = window.innerWidth - parentBox.right;
            } else {
              horizontal = window.innerWidth - (parentBox.left + box.width);
            }
          }
          if (horizontal < 0) {
            horizontal = horizontalOffset;
          }
          ref.current.style.visible = 'hidden';
          ref.current.style.display = 'none';
          setPosition({ top: topPosition, horizontal });
        }
      } else {
        ref.current.style.visible = 'hidden';
        setPosition(null);
      }
    }
  }, [anchorRef, width, ref, horizontalAlign, hAllign]);

  useEffect(() => {
    calculatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorRef, ref.current, component]);

  useEffect(() => {
    if (position?.top || position?.top === 0) {
      ref.current.style.visible = 'visible';
      ref.current.style.display = 'block';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, ref.current]);

  useEffect(() => {
    const onWheel = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        calculatePosition();
      }, 250);
    };
    document.addEventListener('wheel', onWheel);
    return () => {
      document.removeEventListener('wheel', onWheel);
    };
  }, [calculatePosition]);

  return (
    <Container
      ref={ref}
      top={position?.top}
      horizontal={position?.horizontal}
      horizontalAlign={getAlign(hAllign)}
      width={width || anchorBox?.width + 'px'}
    >
      {component}
    </Container>
  );
});

const AnchorPopup: FC<Props> = memo(({ meta, horizontalAlign = HorizontalAlign.LEFT }) => {
  if (!meta) return null;
  return <PopupContent meta={meta} horizontalAlign={horizontalAlign} />;
});

const Container = styled.div<{
  top?: number;
  horizontal?: number;
  horizontalAlign: string;
  width: string;
}>`
  position: absolute;
  top: ${({ top }) => (top ? top : 0)}px;
  z-index: 999;
  width: ${({ width }) => width};
  max-width: calc(100% - ${2 * horizontalOffset}px);
  ${({ horizontal, horizontalAlign }) => css`
    ${horizontalAlign}: ${horizontal ?? 0}px;
  `}
`;

export { AnchorPopup };
