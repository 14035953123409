import React, { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/button';
import { useQuery } from 'react-query';
import { queries } from '../../config/queries';
import { fetchInstance } from '../../utils/axios';
import { api } from '../../config/api';
import Loader from '../../components/loader';
import NoData from '../../components/noData';
import CourseItem from './item';
import { ModalContext } from '../../contexts/modal';
import { ModalsEnum } from '../../config/enums';
import { useApp } from '../../store/app';
import orderBy from 'lodash/orderBy';
import { MAX_LIMIT } from '../../config/consts';

const Courses: FC = memo(() => {
  const setBreadcrumbs = useApp((state) => state.setBreadcrumbs);
  const isAdmin = useApp((state) => state.isAdmin);

  useEffect(() => {
    setBreadcrumbs([{ label: 'Программы', active: true }]);
  }, []);

  const { showModal } = useContext(ModalContext);

  const { data, isLoading } = useQuery(
    [queries.coursesList],
    () =>
      fetchInstance({
        method: 'POST',
        url: api.coursesSearch,
        data: {
          limit: MAX_LIMIT,
          with: {
            relationships: ['modules'],
          },
        },
      }),
    {
      cacheTime: 0,
      onSuccess: (response) => {
        if (isAdmin) {
          const courses = orderBy(response.data, ['name']).map((el) => el.id);
          if (courses.length) {
            fetchInstance({
              method: 'POST',
              url: api.coursesSetPosition,
              data: {
                ids: courses,
              },
            });
          }
        }
      },
    },
  );

  const list = useMemo(() => orderBy(data?.data || [], ['name']), [data]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    } else if (list.length === 0) {
      return <NoData />;
    }
    return list.map((el: any) => <CourseItem key={el.id} {...el} />);
  }, [isLoading, list]);

  return (
    <Wrapper>
      {isAdmin && (
        <ButtonWrapper>
          <Button onClick={() => showModal(ModalsEnum.COURSE_MODAL)}>Добавить программу</Button>
        </ButtonWrapper>
      )}
      <Container>{content}</Container>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  background: #f5f5f9;
  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  gap: 50px;
  height: 100%;
  overflow-y: auto;
`;

export default Courses;
