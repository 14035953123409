import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { api, BASE_URL_STATIC } from '../../../config/api';
import styled from 'styled-components';
import { generateRandomString } from '../../../utils/generateRandomString';
import PlayIcon from '../../../icons/Play';
import { RotatingLines } from 'react-loader-spinner';
import { ExerciseContext } from '../../../contexts/exerciseContext';
import Accordion from '../../../components/accordion';
import { useApp } from '../../../store/app';
import { useExercise } from '../../../store/exercise';
import { fetchInstance } from '../../../utils/axios';
import { autotest_count, autotest_test, start_autotest } from '../../../config/consts';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { toast } from 'react-toastify';
import CrossIcon from '../../../icons/Cross';
import CheckIcon from '../../../icons/Check';

const Result: FC = memo(() => {
  const profile = useApp((state) => state.profile);
  const isAdmin = useApp((state) => state.isAdmin);
  const meta = useExercise((state) => state.meta) as any;
  const { exerciseMeta, setCompleted } = useContext(ExerciseContext);
  const iframeRef = useRef<any>();
  const { exerciseId } = useParams();
  const [key, setKey] = useState(generateRandomString());
  const [loading, setLoading] = useState(false);
  const exerciseUserId = useExercise((state) => state.exerciseUserId);
  const timer = useRef<any>();
  const interval = useRef<any>();
  const saveTimer = useRef<any>();
  const errorTimer = useRef<any>();
  const [localCompleted, setLocalCompleted] = useState(null);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearInterval(interval.current);
      clearTimeout(saveTimer.current);
      clearTimeout(errorTimer.current);
    };
  }, []);

  const startInterval = useCallback(() => {
    const iframeWindow = iframeRef.current.contentWindow || iframeRef.current.contentDocument;
    interval.current = setInterval(async () => {
      if (iframeWindow[autotest_count] === iframeWindow[autotest_test].length) {
        clearInterval(interval.current);
        if (iframeWindow[autotest_test].every((el) => el === true)) {
          await fetchInstance({
            method: 'GET',
            url: api.progressComplete(exerciseId),
          });
          toast.success('Успешно!');
          setCompleted(true);
          setLocalCompleted(true);
        } else {
          const errors = iframeWindow[autotest_test].filter((el) => typeof el === 'string');
          toast.error(`Неверно!\n\n${errors.join('\n')}`);
          setLocalCompleted(false);
          errorTimer.current = setTimeout(() => {
            setLocalCompleted(null);
          }, 3000);
        }
        setLoading(false);
      }
    }, 500);
  }, [exerciseId]);

  const startCheck = useCallback(() => {
    timer.current = setTimeout(() => {
      const iframeWindow = iframeRef.current.contentWindow || iframeRef.current.contentDocument;
      iframeWindow[start_autotest]();
      startInterval();
    }, 500);
  }, [startInterval]);

  const saveMeta = useCallback(async () => {
    const data = {
      course_id: meta.course_id,
      module_id: meta.module_id,
      topic_id: meta.topic_id,
      exercise_id: exerciseId,
      user_id: profile.id,
      sources: meta.sources,
    };
    await fetchInstance({
      method: 'PUT',
      url: isAdmin ? api.exercisesCrud(exerciseId) : api.exerciseUserCRUD(exerciseUserId),
      data,
    });
  }, [meta, exerciseUserId, exerciseId, profile, isAdmin]);

  useDidUpdateEffect(() => {
    clearTimeout(saveTimer.current);
    saveTimer.current = setTimeout(async () => {
      await saveMeta();
      setKey(generateRandomString());
    }, 1000);
  }, [saveMeta]);

  const handleClick = useCallback(async () => {
    setLoading(true);
    await saveMeta();
    setKey(generateRandomString());
    startCheck();
  }, [startCheck, saveMeta]);

  const buttonContent = useMemo(() => {
    if (loading) {
      return (
        <RotatingLines
          strokeColor='#FEFEFF'
          strokeWidth='5'
          animationDuration='1'
          width='20'
          visible={true}
        />
      );
    } else if (localCompleted === false) {
      return <CrossIcon />;
    } else if (localCompleted === true) {
      return <CheckIcon />;
    }
    return <PlayIcon />;
  }, [loading, localCompleted]);

  return (
    <>
      <Accordion label={'Задача'} defaultOpen={true} background={'#F5F5F9'}>
        <div
          style={{ maxHeight: '200px', overflowY: 'auto' }}
          dangerouslySetInnerHTML={{ __html: exerciseMeta.description }}
        />
      </Accordion>
      <iframe
        ref={iframeRef}
        key={key}
        src={BASE_URL_STATIC + api.sourcePath(isAdmin ? exerciseId : exerciseUserId, isAdmin)}
        width={'100%'}
        height={'100%'}
        frameBorder='0'
      />
      {!isAdmin && (
        <ButtonWrapper onClick={loading ? () => null : handleClick}>{buttonContent}</ButtonWrapper>
      )}
    </>
  );
});

const ButtonWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  background: #696cff;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
`;

export default Result;
