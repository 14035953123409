export const queries = {
  coursesList: 'courses-list',
  moduleDetails: 'module-details',
  modulesListByCourse: (courseId: string) => `modules_list_${courseId}`,
  exerciseDetails: 'exercise-details',
  exercisesListByTopic: (topicId: string) => `exercises_list_${topicId}`,
  usersList: 'users-list',
  exerciseUser: 'exercise_user',
  moduleProgress: (id: string) => `module_progress_${id}`,
};
