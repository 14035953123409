import React, { FC, memo, useCallback, useContext } from 'react';
import {
  BlockAlignItemsEnum,
  BlockJustifyContentEnum,
  ButtonVariantEnum,
} from '../../config/enums';
import Button from '../../components/button';
import Block from '../../components/block';
import { useNavigate, useParams } from 'react-router-dom';
import { useExercise } from '../../store/exercise';
import { useMutation } from 'react-query';
import { fetchInstance } from '../../utils/axios';
import { api } from '../../config/api';
import { useApp } from '../../store/app';
import { ExerciseContext } from '../../contexts/exerciseContext';
import { toast } from 'react-toastify';

interface Props {
  showPreviewButton: boolean;
  showCompleteButton: boolean;
  showRetryButton: boolean;
}

const Control: FC<Props> = memo(({ showPreviewButton, showCompleteButton, showRetryButton }) => {
  const navigate = useNavigate();
  const { moduleId, exerciseId } = useParams();
  const isAdmin = useApp((state) => state.isAdmin);
  const { completed } = useContext(ExerciseContext);

  const meta = useExercise((state) => state.meta);
  const setFullSource = useExercise((state) => state.setFullSource);

  const mutation = useMutation((data: any) =>
    fetchInstance({
      method: 'PUT',
      url: api.exercisesCrud(exerciseId),
      data,
    }),
  );

  const onSave = useCallback(() => {
    mutation.mutate({ ...meta, theory: meta.theory || '' });
  }, [meta, exerciseId]);

  const onRetry = useCallback(async () => {
    try {
      const response = await fetchInstance({
        method: 'GET',
        url: api.exercisesCrud(exerciseId),
      });
      const sources = response.data.sources;
      setFullSource(sources);
      const beginResponse = await fetchInstance({
        method: 'GET',
        url: api.progressBegin(exerciseId),
      });
      await fetchInstance({
        method: 'PUT',
        url: api.exerciseUserCRUD(beginResponse?.data?.id),
        data: {
          sources,
        },
      });
    } catch (e) {
      //
    }
  }, [exerciseId]);

  const onNextClick = useCallback(async () => {
    try {
      const response = await fetchInstance({
        method: 'GET',
        url: api.exerciseNext(exerciseId),
      });
      const newExerciseId = response.data.exercise_id;
      const newModuleId = response.data.module_id;
      if (newExerciseId && newModuleId) {
        navigate(`/module/${newModuleId || moduleId}/${newExerciseId}/theory`);
      } else {
        toast.warning('Следующее занятие пока недоступно!');
        navigate('/');
      }
    } catch (e) {
      //
    }
  }, [exerciseId, moduleId]);

  return (
    <Block
      height={'60px'}
      justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
      padding={'0 40px'}
      alignItems={BlockAlignItemsEnum.CENTER}
      background={'#f5f5f9'}
    >
      <Block>{showPreviewButton && isAdmin && <Button>Предпросмотр</Button>}</Block>
      <Block gap={15}>
        {showRetryButton && <Button onClick={onRetry}>Начать заново</Button>}
        <Button
          variant={ButtonVariantEnum.OUTLINED}
          onClick={() => navigate(`/module/${moduleId}`)}
        >
          Отменить
        </Button>
        {isAdmin && (
          <Button onClick={onSave} disabled={mutation.isLoading}>
            Сохранить
          </Button>
        )}
        {!isAdmin && showCompleteButton && (
          <Button disabled={!completed} onClick={onNextClick}>
            Далее
          </Button>
        )}
      </Block>
    </Block>
  );
});

export default Control;
