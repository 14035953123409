import React, { FC, memo } from 'react';
import { useExercise } from '../../store/exercise';
import Wysiwyg from '../../components/wysiwyg';

const Description: FC = memo(() => {
  const description = useExercise((state) => state.meta.description);
  const setMetaItem = useExercise((state) => state.setMetaItem);

  return <Wysiwyg value={description} onChange={(e) => setMetaItem('description', e)} />;
});

export default Description;
