import { create } from 'zustand';

type testType = { name: string; code: string };

interface State {
  meta: {
    theory: any;
    description: any;
    sources: {
      [key: string]: {
        [key: string]: any;
      };
    };
    tests: testType[];
  };
  setMeta: (meta: any) => void;
  clearMeta: VoidFunction;
  setMetaItem: (name: string, value: any) => void;
  setSource: (meta: any, oldFileName: string) => void;
  setFullSource: (sources: any) => void;
  setSourceContent: (fileName: string, content: string) => void;
  removeSource: (fileName: string) => void;
  addAutotest: (meta: testType, index: number) => void;
  removeAutotest: (index: number) => void;
  exerciseUserId: string;
  setExerciseUserId: (exerciseUserId: string) => void;
}

export const useExercise = create<State>((set) => ({
  meta: null,
  setMeta: (meta) => set({ meta }),
  clearMeta: () => set({ meta: null }),
  setMetaItem: (name, value) =>
    set((state) => ({
      meta: {
        ...state.meta,
        [name]: value,
      },
    })),
  setSource: (meta, oldFileName) =>
    set((state) => {
      const sources = { ...state.meta.sources };
      if (oldFileName) {
        delete sources[oldFileName];
      }
      return {
        meta: {
          ...state.meta,
          sources: {
            ...sources,
            [meta.name]: {
              removable: meta.removable,
              editable: meta.editable,
              content: state.meta.sources[meta.name]?.content || '',
              link: meta.link,
            },
          },
        },
      };
    }),
  setFullSource: (sources) =>
    set((state) => {
      return {
        meta: {
          ...state.meta,
          sources,
        },
      };
    }),
  setSourceContent: (fileName, content) =>
    set((state) => ({
      meta: {
        ...state.meta,
        sources: {
          ...state.meta.sources,
          [fileName]: {
            ...state.meta.sources[fileName],
            content,
          },
        },
      },
    })),
  removeSource: (fileName) =>
    set((state) => {
      const sources = { ...state.meta.sources };
      delete sources[fileName];
      return {
        meta: {
          ...state.meta,
          sources,
        },
      };
    }),
  addAutotest: (meta, index) =>
    set((state) => {
      let tests = [];
      if (index === -1) {
        tests = [...state.meta.tests, meta];
      } else {
        tests = state.meta.tests.map((el, idx) => {
          if (idx === index) {
            return meta;
          }
          return el;
        });
      }
      return {
        meta: {
          ...state.meta,
          tests,
        },
      };
    }),
  removeAutotest: (index) =>
    set((state) => {
      return {
        meta: {
          ...state.meta,
          tests: state.meta.tests.filter((el, idx) => idx !== index),
        },
      };
    }),
  exerciseUserId: null,
  setExerciseUserId: (exerciseUserId) => set({ exerciseUserId }),
}));
