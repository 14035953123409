import React, { FC, memo } from 'react';
import Header from '../../modules/header';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import CourseModal from '../../modals/CourseModal';
import ModuleModal from '../../modals/ModuleModal';
import TopicModal from '../../modals/TopicModal';
import ExerciseModal from '../../modals/ExerciseModal';
import FileModal from '../../modules/exercise/Code/FileModal';
import UserModal from '../../modals/UserModal';
import ListTopicsExercisesModal from '../../modals/ListTopicsExercises';
import ConfirmationModal from '../../modules/confirmationModal';

const MainLayout: FC = memo(() => {
  return (
    <>
      <Wrapper>
        <Header />
        <Content>
          <Outlet />
        </Content>
      </Wrapper>
      <CourseModal />
      <ModuleModal />
      <TopicModal />
      <ExerciseModal />
      <FileModal />
      <UserModal />
      <ListTopicsExercisesModal />
      <ConfirmationModal />
    </>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`;

export default MainLayout;
