import React, { FC, memo, useCallback, useMemo } from 'react';
import { useExercise } from '../../../store/exercise';
import {
  BlockFlexDirectionEnum,
  EditorLanguageEnum,
  TypographyVariantEnum,
} from '../../../config/enums';
import Editor from '../../../components/editor';
import { useApp } from '../../../store/app';
import styled from 'styled-components';
import Typography from '../../../components/typography';
import Block from '../../../components/block';
import { toast } from 'react-toastify';

interface Props {
  selectedFile: string;
}

const Content: FC<Props> = memo(({ selectedFile }) => {
  const sources = useExercise((state) => state.meta.sources);
  const setSourceContent = useExercise((state) => state.setSourceContent);
  const isAdmin = useApp((state) => state.isAdmin);

  const { content, language, editable, link } = useMemo(() => {
    const meta = sources[selectedFile];
    const ext = selectedFile.split('.').slice(-1)[0];
    let language = EditorLanguageEnum.HTML;
    if (ext === 'js') {
      language = EditorLanguageEnum.JAVASCRIPT;
    } else if (ext === 'css') {
      language = EditorLanguageEnum.CSS;
    }
    return { content: meta.content, language, editable: meta.editable, link: meta.link };
  }, [selectedFile, sources]);

  const handleChange = useCallback(
    (value: string) => {
      setSourceContent(selectedFile, value);
    },
    [selectedFile, isAdmin, editable],
  );

  const copyToClipboard = useCallback(() => {
    try {
      navigator.clipboard.writeText(link);
      toast.success('Успешно скопировано!');
    } catch (e) {
      //
    }
  }, []);

  if (link) {
    return (
      <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={20} padding={'20px'} width={'100%'}>
        <Typography>Ссылка на файл:</Typography>
        <LinkWrapper onClick={copyToClipboard}>
          <Typography fontSize={20} variant={TypographyVariantEnum.ALTERNATIVE}>
            {link}
          </Typography>
        </LinkWrapper>
        <Img src={link} alt={link} title={link} />
      </Block>
    );
  }

  return (
    <>
      <Editor
        key={selectedFile}
        language={language}
        value={content}
        onChange={handleChange}
        disabled={!isAdmin && !editable}
      />
    </>
  );
});

const Img = styled.img``;

const LinkWrapper = styled.div`
  cursor: pointer;
`;

export default Content;
