import React, { memo, useCallback, useMemo } from 'react';
import { connectModal } from '../contexts/modal/connectModal';
import {
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  BlockOverflowYEnum,
  ButtonTypeEnum,
  ButtonVariantEnum,
  InputTypeEnum,
  ModalsEnum,
} from '../config/enums';
import Modal from '../components/modal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { fetchInstance } from '../utils/axios';
import { api } from '../config/api';
import { queries } from '../config/queries';
import { toast } from 'react-toastify';
import { Form } from '../styles/components';
import Block from '../components/block';
import Button from '../components/button';
import Input from '../components/input';
import TextArea from '../components/textarea';
import Checkbox from '../components/checkbox';
import { ROLES } from '../config/consts';

const UserModal = memo(({ handleHide, ...props }: any) => {
  const schema = useMemo(() => {
    const schema = {
      id: yup.string().nullable(),
      fullname: yup.string().required().max(255),
      login: yup.string().required().max(20),

      note: yup.string().nullable().max(500),
      group: yup.string().nullable().max(20),
      role: yup.string().nullable().max(10),
    } as any;

    schema.password = props.id ? yup.string().nullable() : yup.string().required();
    return yup.object().shape(schema);
  }, []);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: props.id || '',
      fullname: props.fullname || '',
      login: props.login || '',
      password: '',
      note: props.note || '',
      group: props.group || '',
      role: props.role || Object.keys(ROLES)[0],
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: any) =>
      fetchInstance({
        method: data.id ? 'PUT' : 'POST',
        url: api.usersCRUD(data.id),
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.usersList);
        handleHide();
        toast.success('Пользователь успешно сохранен!');
      },
    },
  );

  const removeMutation = useMutation(
    () =>
      fetchInstance({
        method: 'DELETE',
        url: api.usersCRUD(props.id),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.usersList);
        handleHide();
        toast.success('Пользователь удален!');
      },
    },
  );

  const onSubmit = useCallback(
    (data: any) => {
      const params = { ...data };
      if (props.id && !data.password) {
        delete params.password;
      }
      mutation.mutate(params);
    },
    [mutation],
  );

  return (
    <Modal
      title={`${props.id ? 'Редактирование' : 'Добавление'} пользователя`}
      handleHide={handleHide}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Block
          flexDirection={BlockFlexDirectionEnum.COLUMN}
          justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
          height={'100%'}
          overflowY={BlockOverflowYEnum.AUTO}
        >
          <Block gap={30} flexDirection={BlockFlexDirectionEnum.COLUMN}>
            <Controller
              render={({ field, fieldState }) => (
                <Input {...field} {...fieldState} label={'ФИО'} placeholder={'ФИО'} />
              )}
              name={'fullname'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Логин'}
                  placeholder={'Логин'}
                  disabled={props.id}
                />
              )}
              name={'login'}
              control={control}
            />

            <Controller
              render={({ field, fieldState }) => (
                <TextArea {...field} {...fieldState} label={'Заметки'} placeholder={'Заметки'} />
              )}
              name={'note'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <Input {...field} {...fieldState} label={'Группа'} placeholder={'Группа'} />
              )}
              name={'group'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => {
                return (
                  <Block gap={25}>
                    {Object.keys(ROLES).map((role) => (
                      <Checkbox
                        key={`role-${role}`}
                        value={field.value === role}
                        onChange={() => field.onChange(role)}
                        label={ROLES[role]}
                      />
                    ))}
                  </Block>
                );
              }}
              name={'role'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Пароль'}
                  placeholder={'Пароль'}
                  type={InputTypeEnum.PASSWORD}
                />
              )}
              name={'password'}
              control={control}
            />
          </Block>
          <Block
            gap={15}
            justifyContent={
              props.id ? BlockJustifyContentEnum.SPACE_BETWEEN : BlockJustifyContentEnum.FLEX_END
            }
          >
            {props.id && (
              <Button variant={ButtonVariantEnum.WARNING} onClick={removeMutation.mutate}>
                Удалить
              </Button>
            )}
            <Block gap={15}>
              <Button width={'auto'} variant={ButtonVariantEnum.OUTLINED} onClick={handleHide}>
                Отменить
              </Button>
              <Button width={'auto'} type={ButtonTypeEnum.SUBMIT} disabled={mutation.isLoading}>
                Сохранить
              </Button>
            </Block>
          </Block>
        </Block>
      </Form>
    </Modal>
  );
});

export default connectModal(ModalsEnum.USER_MODAL)(UserModal);
