import React, { FC, memo } from 'react';

const CrossIcon: FC = memo(() => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2 2L22.5 23' stroke='white' strokeWidth='3' strokeLinecap='round' />
      <path d='M22 2L2 23.5' stroke='white' strokeWidth='3' strokeLinecap='round' />
    </svg>
  );
});

export default CrossIcon;
