import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchInstance } from '../../../utils/axios';
import { api } from '../../../config/api';
import Loader from '../../../components/loader';
import { useExercise } from '../../../store/exercise';
import Code from '../Code';
import { ExerciseContext } from '../../../contexts/exerciseContext';

const TaskCode: FC = memo(() => {
  const { exerciseId } = useParams();
  const [loading, setLoading] = useState(true);
  const setMeta = useExercise((state) => state.setMeta);
  const setExerciseUserId = useExercise((state) => state.setExerciseUserId);
  const { exerciseMeta } = useContext(ExerciseContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchInstance({
          method: 'GET',
          url: api.progressBegin(exerciseId),
        });
        setExerciseUserId(response.data.id);
        setMeta({ ...(exerciseMeta || {}), ...response.data });
        setLoading(false);
      } catch (e) {
        //
      }
    };
    fetchData();
  }, [exerciseMeta]);

  if (loading) return <Loader />;

  return <Code />;
});

export default TaskCode;
