import React, { useContext } from 'react';
import { ModalContext } from './index';

export const connectModal = (modalName: string) => (Component: any) => {
  return (componentProps: any) => {
    const { modals, hideModal } = useContext(ModalContext);
    if (!modals[modalName]) return null;
    return (
      <Component
        handleHide={() => hideModal(modalName)}
        {...componentProps}
        {...modals[modalName]}
      />
    );
  };
};
