import React, { FC, memo, useEffect, useState } from 'react';
import AutotestList from './List';
import AutotestDetails from './Details';
import { useExercise } from '../../../store/exercise';
import { useMutation } from 'react-query';
import { fetchInstance } from '../../../utils/axios';
import { api } from '../../../config/api';
import { useParams } from 'react-router-dom';

const Autotest: FC = memo(() => {
  const [autotestIndex, setAutotestIndex] = useState(null);
  const { exerciseId } = useParams();

  const meta = useExercise((state) => state.meta);

  const mutation = useMutation((data: any) =>
    fetchInstance({
      method: 'PUT',
      url: api.exercisesCrud(exerciseId),
      data,
    }),
  );

  useEffect(() => {
    mutation.mutate(meta);
  }, [meta]);

  if (autotestIndex === null) {
    return <AutotestList setAutotestIndex={setAutotestIndex} />;
  }
  return <AutotestDetails setAutotestIndex={setAutotestIndex} autotestIndex={autotestIndex} />;
});

export default Autotest;
