import { createGlobalStyle } from 'styled-components';
import GothamPro from '../assets/fonts/GothamPro.woff';
export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: GothamPro, sans-serif;
  }

  html, body, #root {
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 0;
  }

  ::-webkit-scrollbar-track {
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .12);
    border-radius: 50px;
    height: 3rem;
    border-left: 3px solid rgba(0, 0, 0, 0); /* Transparent border together with \`background-clip: padding-box\` does the trick */
    border-right: 3px solid rgba(0, 0, 0, 0); /* Transparent border together with \`background-clip: padding-box\` does the trick */
    border-bottom: 10px solid rgba(0, 0, 0, 0);
    border-top: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 1em;
    //-webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  }
  
  @font-face {
    font-family: "GothamPro";
    src: url("${GothamPro}") format("woff");
  }

  .Toastify__toast-body {
    white-space: pre-line;
  }

  ol li {
    list-style: decimal;
    list-style-position: inside;
  }
`;
