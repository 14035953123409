import React, { FC, memo, useCallback, useState } from 'react';
import Block from '../../../components/block';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  BlockFlexEnum,
  BlockJustifyContentEnum,
  ButtonVariantEnum,
  EditorLanguageEnum,
  TypographyVariantEnum,
} from '../../../config/enums';
import Typography from '../../../components/typography';
import Input from '../../../components/input';
import Editor from '../../../components/editor';
import Button from '../../../components/button';
import { useExercise } from '../../../store/exercise';
import { autotest_test } from '../../../config/consts';

interface Props {
  setAutotestIndex: (value: number) => void;
  autotestIndex: number;
}

const AutotestDetails: FC<Props> = memo(({ setAutotestIndex, autotestIndex }) => {
  const meta = useExercise((state) => state.meta.tests[autotestIndex]);
  const addAutotest = useExercise((state) => state.addAutotest);

  const [name, setName] = useState<any>(meta?.name || '');
  const [code, setCode] = useState(meta?.code || '(function(){\n\n})();');

  const onSave = useCallback(() => {
    addAutotest({ name, code }, autotestIndex);
    setAutotestIndex(null);
  }, [name, code, autotestIndex]);

  return (
    <Block
      background={'#f5f5f9'}
      flexDirection={BlockFlexDirectionEnum.COLUMN}
      flex={BlockFlexEnum.FLEX_1_1_AUTO}
    >
      <Block padding={'30px'} flexDirection={BlockFlexDirectionEnum.COLUMN} gap={10}>
        <Typography fontSize={28} variant={TypographyVariantEnum.ALTERNATIVE} bold>
          {autotestIndex === -1 ? 'Создание ' : 'Редактирование '}автотеста
        </Typography>
      </Block>
      <Block
        padding={'0 30px'}
        gap={40}
        flexDirection={BlockFlexDirectionEnum.COLUMN}
        height={'100%'}
      >
        <Input value={name} onChange={setName} label={'Название'} placeholder={'Автотест 1'} />
        <Block width={'100%'} height={'100%'}>
          <Editor language={EditorLanguageEnum.JAVASCRIPT} value={code} onChange={setCode} />
        </Block>
      </Block>
      <Block
        height={'60px'}
        justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
        alignItems={BlockAlignItemsEnum.CENTER}
        background={'#f5f5f9'}
        flex={BlockFlexEnum.FLEX_0_0_AUTO}
        padding={'0 30px'}
      >
        <Block>
          <Typography variant={TypographyVariantEnum.ALTERNATIVE}>
            — в конце каждого автотеста ставим точку с запятой (;) <br />— всегда используем
            самовызывающуюся функцию <br />— название ключа куда пушить либо true либо текст ошибки:{' '}
            {autotest_test}
          </Typography>
        </Block>
        <Block gap={15}>
          <Button variant={ButtonVariantEnum.OUTLINED} onClick={() => setAutotestIndex(null)}>
            Отменить
          </Button>
          <Button onClick={onSave} disabled={!name || !code}>
            Сохранить
          </Button>
        </Block>
      </Block>
    </Block>
  );
});

export default AutotestDetails;
