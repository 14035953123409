import { defaultFileName } from './consts';

export const BASE_URL_API = process.env.REACT_APP_BASE_URL;
export const BASE_URL_STORAGE_API = process.env.REACT_APP_BASE_URL_STORAGE;
export const BASE_URL_STATIC = process.env.REACT_APP_STATIC_URL;

export const api = {
  authLogin: '/auth/login',
  authLogout: '/auth/logout',
  authProfile: '/auth/profile',

  coursesSearch: '/courses/search',
  coursesCRUD: (id?: string) => (id ? `/courses/${id}` : '/courses'),
  coursesSetPosition: '/courses/setPosition',

  modulesSearch: '/modules/search',
  modulesCRUD: (id?: string) => (id ? `/modules/${id}` : '/modules'),
  modulesSetPosition: '/modules/setPosition',

  topicsSearch: '/topics/search',
  topicsCRUD: (id?: string) => (id ? `/topics/${id}` : '/topics'),
  topicsSetPosition: '/topics/setPosition',

  exercisesSearch: '/exercises/search',
  exercisesCrud: (id?: string) => (id ? `/exercises/${id}` : '/exercises'),
  exercisesSetPosition: '/exercises/setPosition',
  exerciseNext: (id?: string) => `/exercises/${id}/next`,

  usersSearch: '/users/search',
  usersCRUD: (id?: string) => (id ? `/users/${id}` : '/users'),
  usersExport: '/users/exportActivity',

  getContentAdmin: (exerciseId: string) => `/exercises/${exerciseId}/${defaultFileName}`,

  progressBegin: (exerciseId: string) => `/progress/${exerciseId}/begin`,
  progressComplete: (exerciseId: string) => `/progress/${exerciseId}/complete`,

  exerciseUserSearch: '/exerciseUsers/search',
  exerciseUserCRUD: (id?: string) => (id ? `/exerciseUsers/${id}` : '/exerciseUsers'),

  sourcePath: (exerciseId: string, isAdmin: boolean) =>
    `/${isAdmin ? 'exercise' : 'check'}/${exerciseId}/index.html`,
};

export const apiStorage = {
  simpleUpload: '/storage/simpleUpload',
};
