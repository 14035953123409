import React, { FC, memo, useContext, useMemo } from 'react';
import Block from '../../../components/block';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  ButtonVariantEnum,
  ModalsEnum,
  TypographyVariantEnum,
} from '../../../config/enums';
import Typography from '../../../components/typography';
import Button from '../../../components/button';
import IconButton from '../../../components/iconButton';
import EditIcon from '../../../icons/Edit';
import { openLinkInNewTab } from '../../../utils/openLinkInNewTab';
import { ModalContext } from '../../../contexts/modal';
import Grid from '../../../components/grid';
import AddCard from '../../../components/addCard';
import ModuleCard from './moduleCard';
import { getItemsInRow } from '../../../utils/getItemsInRow';
import { useApp } from '../../../store/app';
import orderBy from 'lodash/orderBy';
import { useQuery } from 'react-query';
import { queries } from '../../../config/queries';
import { fetchInstance } from '../../../utils/axios';
import { api } from '../../../config/api';
import Loader from '../../../components/loader';
import { MAX_LIMIT } from '../../../config/consts';

interface Props {
  id: string;
  name: string;
  description?: string;
  links?: any[];
}

const CourseItem: FC<Props> = memo(({ id, name, description, links }) => {
  const { showModal } = useContext(ModalContext);
  const isAdmin = useApp((state) => state.isAdmin);

  const { data, isLoading } = useQuery(
    [queries.modulesListByCourse(id)],
    () =>
      fetchInstance({
        method: 'POST',
        url: api.modulesSearch,
        data: {
          page: 1,
          limit: MAX_LIMIT,
          filter: [
            {
              column: 'course_id',
              value: id,
            },
          ],
          with: {
            properties: ['progress'],
          },
        },
      }),
    {
      cacheTime: 0,
      onSuccess: (response) => {
        if (isAdmin) {
          const modules = orderBy(response.data || [], ['name']).map((el) => el.id);
          if (modules.length) {
            fetchInstance({
              method: 'POST',
              url: api.modulesSetPosition,
              data: {
                ids: modules,
              },
            });
          }
        }
      },
    },
  );

  const modules = useMemo(() => orderBy(data?.data || [], ['name']), [data]);

  return (
    <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={25}>
      <Block gap={15} alignItems={BlockAlignItemsEnum.CENTER}>
        <Typography fontSize={20} variant={TypographyVariantEnum.ALTERNATIVE} bold={true}>
          {name}
        </Typography>
        {isAdmin && (
          <IconButton
            onClick={() => showModal(ModalsEnum.COURSE_MODAL, { id, name, description, links })}
          >
            <EditIcon />
          </IconButton>
        )}
      </Block>
      {description && (
        <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE}>
          {description}
        </Typography>
      )}
      {links && (
        <Block gap={10}>
          {links.map((el, idx) => (
            <Button
              key={`${id}_link-${idx}`}
              variant={ButtonVariantEnum.OUTLINED}
              height={30}
              onClick={() => openLinkInNewTab(el.link)}
            >
              {el.name}
            </Button>
          ))}
        </Block>
      )}
      <Grid itemsInRow={getItemsInRow()} gap={15}>
        {isLoading ? (
          <Loader />
        ) : (
          modules.map((module) => <ModuleCard key={module.id} {...module} />)
        )}
        {isAdmin && (
          <AddCard onClick={() => showModal(ModalsEnum.MODULE_MODAL, { course_id: id })} />
        )}
      </Grid>
    </Block>
  );
});

export default CourseItem;
