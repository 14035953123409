import React, { FC, memo } from 'react';
import Block from '../block';
import { BlockAlignItemsEnum, TypographyVariantEnum } from '../../config/enums';
import Typography from '../typography';
import styled from 'styled-components';

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
}

const Checkbox: FC<Props> = memo(({ value, label, onChange }) => {
  return (
    <Block alignItems={BlockAlignItemsEnum.CENTER} gap={10} onClick={() => onChange(!value)}>
      <Box>{value && <Checked />}</Box>
      <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE}>
        {label}
      </Typography>
    </Block>
  );
});

const Box = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid rgba(105, 108, 255, 0.5);
  background: #f5f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checked = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.primary};
`;

export default Checkbox;
