import React, { FC, memo, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from './guards/auth';
import MainLayout from './layouts/main';
import Courses from './pages/courses';
import Loader from './components/loader';
import Storage from './utils/storage';
import { fetchInstance, setToken } from './utils/axios';
import { api } from './config/api';
import { useApp } from './store/app';
import CourseModal from './modals/CourseModal';
import ModuleModal from './modals/ModuleModal';
import Modules from './pages/modules';
import TopicModal from './modals/TopicModal';
import ExerciseModal from './modals/ExerciseModal';
import Exercise from './pages/exercise';
import FileModal from './modules/exercise/Code/FileModal';
import Users from './pages/users';
import ConfirmationModal from './modules/confirmationModal';
import UserModal from './modals/UserModal';
import ListTopicsExercisesModal from './modals/ListTopicsExercises';

const App: FC = memo(() => {
  const [loading, setLoading] = useState(true);
  const setProfile = useApp((state) => state.setProfile);

  useEffect(() => {
    const token = Storage.getToken();
    if (token) {
      setToken(token);
      const fetchProfile = async () => {
        try {
          const response = await fetchInstance({
            method: 'GET',
            url: api.authProfile,
          });
          setProfile(response.data);
        } catch (e) {
          //
        } finally {
          setLoading(false);
        }
      };
      fetchProfile();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthGuard>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path={'/'} element={<Courses />} />
          <Route path={'/courses'} element={<Courses />} />
          <Route path={'/module/:moduleId'} element={<Modules />} />
          <Route path={'/module/:moduleId/:exerciseId'} element={<Exercise />} />
          <Route path={'/module/:moduleId/:exerciseId/:tab'} element={<Exercise />} />
          <Route path={'/users'} element={<Users />} />
        </Route>
      </Routes>
    </AuthGuard>
  );
});

export default App;
