import { create } from 'zustand';

type breadcrumbType = { label: any; active?: boolean; action?: VoidFunction };

interface State {
  profile: any;
  setProfile: (profile: any) => void;
  isAdmin: boolean;

  breadcrumbs: breadcrumbType[];
  setBreadcrumbs: (list: breadcrumbType[]) => void;
}

export const useApp = create<State>((set) => ({
  profile: null,
  setProfile: (profile) => {
    return set({ profile, isAdmin: profile?.role == 'admin' });
  },
  isAdmin: false,
  breadcrumbs: [],
  setBreadcrumbs: (breadcrumbs) => set({ breadcrumbs }),
}));
