import React, { FC, memo, PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  TypographyTextAlignEnum,
  TypographyTextDecorationEnum,
  TypographyVariantEnum,
  TypographyWritingModeEnum,
} from '../../config/enums';

interface Props {
  color?: string;
  fontSize?: number;
  italic?: boolean;
  textAlign?: TypographyTextAlignEnum;
  bold?: boolean;
  cursor?: string;
  variant?: TypographyVariantEnum;
  vertical?: boolean;
  textDecoration?: TypographyTextDecorationEnum;
}

const Typography: FC<PropsWithChildren & Props> = memo(
  ({
    color,
    children,
    fontSize = 14,
    italic = false,
    textAlign = TypographyTextAlignEnum.START,
    bold = false,
    cursor,
    variant = TypographyVariantEnum.DEFAULT,
    vertical = false,
    textDecoration,
  }) => {
    return (
      <Wrapper
        fontSize={fontSize}
        italic={italic}
        textAlign={textAlign}
        bold={bold}
        cursor={cursor}
        variant={variant}
        color={color}
        vertical={vertical}
        textDecoration={textDecoration}
      >
        {children}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{
  fontSize: number;
  italic: boolean;
  textAlign: TypographyTextAlignEnum;
  bold: boolean;
  cursor: string;
  variant: TypographyVariantEnum;
  color: string;
  vertical: boolean;
  textDecoration?: TypographyTextDecorationEnum;
}>`
  white-space: pre-wrap;
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ color, theme, variant }) => {
    if (color) {
      return color;
    } else if (variant === TypographyVariantEnum.ALTERNATIVE) {
      return theme.colors.typography2;
    }
    return theme.colors.typography;
  }};
  font-size: ${({ fontSize }) => fontSize}px;
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  text-align: ${({ textAlign }) => textAlign};
  ${({ cursor }) => {
    if (cursor) {
      return { cursor };
    }
  }}
  ${({ vertical }) => {
    if (vertical) {
      return { writingMode: 'vertical-lr', transform: 'rotate(180deg)' };
    }
  }}
  ${({ textDecoration }) => {
    if (textDecoration) {
      return { textDecoration };
    }
  }}
`;

export default Typography;
