import React, { FC, Fragment, memo } from 'react';
import Block from '../block';
import Typography from '../typography';
import { BlockAlignItemsEnum, BlockFlexWrapEnum } from '../../config/enums';
import { useApp } from '../../store/app';

const Breadcrumbs: FC = memo(() => {
  const breadcrumbs = useApp((state) => state.breadcrumbs);

  return (
    <Block gap={5} alignItems={BlockAlignItemsEnum.CENTER} flexWrap={BlockFlexWrapEnum.WRAP}>
      {breadcrumbs.map((el, idx) => (
        <Fragment key={`breadcrumbs-${idx}`}>
          <Block onClick={el.action}>
            <Typography bold={el.active} cursor={el.action && 'pointer'}>
              {el.label}
            </Typography>
          </Block>
          {idx + 1 < breadcrumbs.length && <Typography>/</Typography>}
        </Fragment>
      ))}
    </Block>
  );
});

export default Breadcrumbs;
