import React, { FC, memo, useMemo } from 'react';
import Block from '../block';
import { BlockFlexDirectionEnum } from '../../config/enums';
import Typography from '../typography';
import styled from 'styled-components';

interface Props {
  progress: number;
}

const Progress: FC<Props> = memo(({ progress }) => {
  const color = useMemo(() => {
    if (progress === 100) {
      return '#58C432';
    }
    return '#F7542E';
  }, [progress]);
  return (
    <Block flexDirection={BlockFlexDirectionEnum.COLUMN}>
      <Block gap={5}>
        <Typography color={color} fontSize={14}>
          Прогресс
        </Typography>
        <Typography color={color} fontSize={14}>
          {progress}%
        </Typography>
      </Block>
      <Bar color={color}>
        <ActiveBar progress={progress} color={color} />
      </Bar>
    </Block>
  );
});

const Bar = styled.div<{ color: string }>`
  width: 100%;
  position: relative;
  border: 1px solid ${({ color }) => color};
  height: 8px;
  border-radius: 4px;
`;

const ActiveBar = styled.div<{ progress: number; color: string }>`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background: ${({ color }) => color};
  width: ${({ progress }) => progress}%;
  border-radius: 4px;
`;

export default Progress;
