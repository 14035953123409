import { useEffect, useRef } from 'react';

const useDidUpdateEffect = (func, deps) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      func();
    } else {
      isFirstRender.current = false;
    }
  }, deps);
};

export { useDidUpdateEffect };
