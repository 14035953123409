import React, { FC, memo } from 'react';

const LogoutIcon: FC = memo(() => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25 11.8919V8C25 6.34315 23.6569 5 22 5H9C7.34315 5 6 6.34315 6 8V32C6 33.6569 7.34315 35 9 35H22C23.6569 35 25 33.6569 25 32V28.5135'
        stroke='#696CFF'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M16 19C15.4477 19 15 19.4477 15 20C15 20.5523 15.4477 21 16 21V19ZM35.7071 20.7071C36.0976 20.3166 36.0976 19.6834 35.7071 19.2929L29.3431 12.9289C28.9526 12.5384 28.3195 12.5384 27.9289 12.9289C27.5384 13.3195 27.5384 13.9526 27.9289 14.3431L33.5858 20L27.9289 25.6569C27.5384 26.0474 27.5384 26.6805 27.9289 27.0711C28.3195 27.4616 28.9526 27.4616 29.3431 27.0711L35.7071 20.7071ZM16 21H35V19H16V21Z'
        fill='#696CFF'
      />
    </svg>
  );
});

export default LogoutIcon;
