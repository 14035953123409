import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { TableFieldsType } from '../../config/interfaces';
import TableHead from './Head';
import TableBody from './Body';

interface Props {
  fields: TableFieldsType[];
  data: any[];
}

const Table: FC<Props> = memo(({ fields, data }) => {
  return (
    <TableWrapper>
      <TableHead fields={fields} />
      <TableBody fields={fields} data={data} />
    </TableWrapper>
  );
});

const TableWrapper = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
`;

export default Table;
