import React, { memo, useContext, useEffect } from 'react';
import { connectModal } from '../contexts/modal/connectModal';
import {
  BlockFlexDirectionEnum,
  ModalsEnum,
  TypographyTextDecorationEnum,
  TypographyVariantEnum,
} from '../config/enums';
import styled from 'styled-components';
import { disabledState, leftMenuEditorId } from '../config/consts';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { queries } from '../config/queries';
import { fetchInstance } from '../utils/axios';
import { api } from '../config/api';
import Block from '../components/block';
import Typography from '../components/typography';
import Loader from '../components/loader';
import groupBy from 'lodash/groupBy';
import Accordion from '../components/accordion';
import { ModalContext } from '../contexts/modal';
import orderBy from 'lodash/orderBy';

const Content = memo(({ module }: any) => {
  const { hideModal } = useContext(ModalContext);
  const groupedExercise = groupBy(module.exercises, 'topic_id');
  const navigate = useNavigate();

  return (
    <Block flexDirection={BlockFlexDirectionEnum.COLUMN}>
      <Block padding={'20px 125px 10px 10px'}>
        <Typography fontSize={14} variant={TypographyVariantEnum.ALTERNATIVE} bold>
          {module?.name}
        </Typography>
      </Block>
      {orderBy(module.topics, ['name']).map((topic) => {
        const exercises = groupedExercise[topic.id];
        const topicMeta = module.topics.find((el) => el.id === topic.id);
        return (
          <Accordion key={`accordion-${topic.id}`} label={topicMeta.name}>
            <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={2}>
              {orderBy(exercises, ['name']).map((exercise) => {
                const isDisabled = exercise.state === disabledState;
                const onClick = () => {
                  navigate(`/module/${module.id}/${exercise.id}/theory`);
                  hideModal(ModalsEnum.LIST_TOPICS_EXERCISES);
                };
                return (
                  <Block key={`exercise-${exercise.id}`} onClick={isDisabled ? undefined : onClick}>
                    <Typography
                      variant={!isDisabled && TypographyVariantEnum.ALTERNATIVE}
                      fontSize={14}
                      textDecoration={!isDisabled && TypographyTextDecorationEnum.UNDERLINE}
                    >
                      {exercise.name}
                    </Typography>
                  </Block>
                );
              })}
            </Block>
          </Accordion>
        );
      })}
    </Block>
  );
});

const ListTopicsExercisesModal = memo(({ handleHide }: any) => {
  const { moduleId, exerciseId } = useParams();
  useEffect(() => {
    const leftMenuEditor = document.getElementById(leftMenuEditorId);
    leftMenuEditor.style.zIndex = String(1);
    return () => {
      leftMenuEditor.style.zIndex = null;
    };
  }, []);

  const ref = useOutsideClick(handleHide);
  const { data: moduleData, isLoading } = useQuery(
    [queries.moduleDetails],
    () =>
      fetchInstance({
        method: 'GET',
        url: api.modulesCRUD(moduleId),
      }),
    {
      cacheTime: 0,
    },
  ) as any;

  const module = moduleData?.data;

  return (
    <>
      <ModalBackground />
      <Wrapper ref={ref}>{isLoading ? <Loader /> : <Content module={module} />}</Wrapper>
    </>
  );
});

const Wrapper = styled.div`
  position: absolute;
  background: #f5f5f9;
  top: 60px;
  left: 60px;
  width: 410px;
  height: calc(100% - 60px);
  border-radius: 0 20px 0 0;
  border-left: 1px solid rgba(105, 108, 255, 0.2);
`;

const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
`;

export default connectModal(ModalsEnum.LIST_TOPICS_EXERCISES)(ListTopicsExercisesModal);
