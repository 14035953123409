import React, { FC, memo } from 'react';
import { useExercise } from '../../store/exercise';
import Wysiwyg from '../../components/wysiwyg';

const Theory: FC = memo(() => {
  const theory = useExercise((state) => state.meta.theory);
  const setMetaItem = useExercise((state) => state.setMetaItem);

  return <Wysiwyg value={theory} onChange={(e) => setMetaItem('theory', e)} />;
});

export default Theory;
