import React, { FC, memo } from 'react';
import { connectModal } from '../../contexts/modal/connectModal';
import {
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  ModalPositionEnum,
  ModalsEnum,
  TypographyVariantEnum,
} from '../../config/enums';
import Modal from '../../components/modal';
import { ModalCommon } from '../../config/interfaces';
import Block from '../../components/block';
import Typography from '../../components/typography';
import Button from '../../components/button';

export interface Props {
  title: string;
  description?: string;
  buttonTitle?: string;
  cancelTitle?: string;
  onClick?: VoidFunction;
  showCancelButton?: boolean;
}

const ConfirmationModal: FC<ModalCommon & Props> = memo(
  ({
    handleHide,
    title,
    onClick,
    buttonTitle = 'ОК',
    cancelTitle = 'Отмена',
    description,
    showCancelButton = true,
  }) => {
    return (
      <Modal handleHide={handleHide} position={ModalPositionEnum.CENTER}>
        <Block gap={20} flexDirection={BlockFlexDirectionEnum.COLUMN}>
          <Typography fontSize={20} variant={TypographyVariantEnum.ALTERNATIVE}>
            {title}
          </Typography>
          {description && (
            <Typography variant={TypographyVariantEnum.ALTERNATIVE}>{description}</Typography>
          )}
          <Block gap={10} justifyContent={BlockJustifyContentEnum.FLEX_END}>
            <Button
              onClick={() => {
                onClick && onClick();
                handleHide();
              }}
            >
              {buttonTitle}
            </Button>
            {showCancelButton && <Button onClick={handleHide}>{cancelTitle}</Button>}
          </Block>
        </Block>
      </Modal>
    );
  },
);

export default connectModal(ModalsEnum.CONFIRMATION_MODAL)(ConfirmationModal);
