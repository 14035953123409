import React, { FC, memo, useCallback, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { useApp } from '../../store/app';
import { fetchInstanceStorage } from '../../utils/axios';
import { apiStorage } from '../../config/api';

interface Props {
  value: any;
  onChange: (value: any) => void;
}

const Wysiwyg: FC<Props> = memo(({ value, onChange }) => {
  const isAdmin = useApp((state) => state.isAdmin);
  const quillRef = useRef<any>();

  const onUploadImage = useCallback(async () => {
    try {
      const input = document.createElement('input');

      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = async () => {
        const file: any = input.files[0];

        const formData = new FormData();
        formData.append('bucket', 'med-it');
        formData.append('path', '/');
        formData.append('file', file);
        const response = await fetchInstanceStorage({
          method: 'POST',
          url: apiStorage.simpleUpload,
          data: formData,
        });
        if (response.data?.url) {
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          quill.editor.insertEmbed(range.index, 'image', response.data.url);
          onChange(quillRef.current.editor.root.innerHTML);
        }
      };
    } catch (e) {
      //
    }
  }, []);

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ align: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ color: [] }, { background: [] }],
          ['link', 'image'],
        ],
        handlers: {
          image: onUploadImage,
        },
      },
    };
  }, []);

  if (isAdmin) {
    return (
      <Wrapper width={'100%'}>
        <ReactQuill
          ref={quillRef}
          theme='snow'
          value={value}
          onChange={onChange}
          modules={modules}
        />
      </Wrapper>
    );
  }
  return (
    <Wrapper className='quill' width={'50%'}>
      <div className='ql-container ql-snow'>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: value }} />
      </div>
    </Wrapper>
  );
});

const Wrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 100%;
  overflow: hidden;

  .quill {
    overflow: hidden;
    height: 100%;
  }

  .ql-toolbar {
    border: none;
  }

  .ql-container {
    border: none;
    height: calc(100% - 40px);
  }
`;

export default Wysiwyg;
