import axios from 'axios';
import { BASE_URL_API, BASE_URL_STORAGE_API } from '../config/api';
import { toast } from 'react-toastify';
export const fetchInstance = axios.create();
fetchInstance.defaults.baseURL = BASE_URL_API;
export const fetchInstanceStorage = axios.create();
fetchInstanceStorage.defaults.baseURL = BASE_URL_STORAGE_API;

export const setToken = (token?: string) => {
  if (token) {
    fetchInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete fetchInstance.defaults.headers.common['Authorization'];
  }
};

const handleError = (errorResponse: any) => {
  const error = errorResponse?.response?.data?.error;
  let err = '';
  if (typeof error === 'string') {
    err = error;
  } else if (typeof error === 'object') {
    Object.values(error).forEach((e) => (err += `${e}\n`));
  }
  console.log(errorResponse);
  if (err) toast.error(err);
  return Promise.reject(error);
};

fetchInstance.interceptors.response.use((response) => response.data, handleError);
fetchInstanceStorage.interceptors.response.use((response) => response.data, handleError);
