import React, { FC, memo, PropsWithChildren, useState } from 'react';
import Block from '../block';
import {
  BlockFlexDirectionEnum,
  BlockFlexEnum,
  BlockJustifyContentEnum,
  TypographyVariantEnum,
} from '../../config/enums';
import IconButton from '../iconButton';
import ArrowIcon from '../../icons/Arrow';
import Typography from '../typography';

interface Props {
  label: string;
  defaultOpen?: boolean;
  background?: string;
}

const Accordion: FC<Props & PropsWithChildren> = memo(
  ({ label, children, defaultOpen = false, background = 'rgba(247, 84, 46, 0.10)' }) => {
    const [open, setOpen] = useState(defaultOpen);
    return (
      <Block
        flexDirection={BlockFlexDirectionEnum.COLUMN}
        background={open ? background : 'transparent'}
      >
        <Block
          justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
          padding={'5px 10px 5px 10px'}
          onClick={() => setOpen(!open)}
        >
          <Block flex={BlockFlexEnum.FLEX_1_1_AUTO}>
            <Typography variant={TypographyVariantEnum.ALTERNATIVE} fontSize={28} bold>
              {label}
            </Typography>
          </Block>
          <Block flex={BlockFlexEnum.FLEX_0_0_AUTO}>
            <ArrowIcon rotate={open ? 0 : 180} />
          </Block>
        </Block>
        {open && <Block padding={'10px'}>{children}</Block>}
      </Block>
    );
  },
);

export default Accordion;
