import React, { FC, memo, PropsWithChildren } from 'react';
import { useApp } from '../store/app';
import Login from '../pages/login';

const AuthGuard: FC<PropsWithChildren> = memo(({ children }: any) => {
  const profile = useApp((state) => state.profile);

  if (!profile) {
    return <Login />;
  }

  return children;
});

export default AuthGuard;
