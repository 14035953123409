import React, { memo, useCallback, useContext } from 'react';
import Modal from '../components/modal';
import Input from '../components/input';
import styled from 'styled-components';
import TextArea from '../components/textarea';
import {
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  BlockOverflowYEnum,
  ButtonTypeEnum,
  ButtonVariantEnum,
  ModalsEnum,
  TypographyVariantEnum,
} from '../config/enums';
import Button from '../components/button';
import * as yup from 'yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from '../styles/components';
import Block from '../components/block';
import Typography from '../components/typography';
import IconButton from '../components/iconButton';
import TrashIcon from '../icons/Trash';
import { useMutation, useQueryClient } from 'react-query';
import { fetchInstance } from '../utils/axios';
import { api } from '../config/api';
import { queries } from '../config/queries';
import { connectModal } from '../contexts/modal/connectModal';
import { toast } from 'react-toastify';
import { ModalContext } from '../contexts/modal';

const schema = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required(),
  description: yup.string().nullable(),
  links: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        link: yup.string().required(),
      }),
    )
    .nullable(),
});

const CourseModal = memo(({ handleHide, ...props }: any) => {
  const { showModal } = useContext(ModalContext);
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: props.id || '',
      name: props.name || '',
      description: props.description || '',
      links: props.links || [],
    },
  });

  const {
    fields: fieldsLinks,
    append: appendLinks,
    remove: removeLinks,
  } = useFieldArray({
    control,
    name: 'links',
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: any) =>
      fetchInstance({
        method: data.id ? 'PUT' : 'POST',
        url: api.coursesCRUD(data.id),
        data,
      }),
    {
      onSuccess: (response) => {
        console.log(response);
        queryClient.invalidateQueries(queries.coursesList);
        handleHide();
        toast.success('Курс успешно сохранен!');
      },
    },
  );

  const removeMutation = useMutation(
    () =>
      fetchInstance({
        method: 'DELETE',
        url: api.coursesCRUD(props.id),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.coursesList);
        handleHide();
        toast.success('Курс удален!');
      },
    },
  );

  const onSubmit = useCallback(
    (data) => {
      mutation.mutate(data);
    },
    [mutation],
  );

  return (
    <Modal
      title={`${props.id ? 'Редактирование' : 'Добавление'} программы`}
      handleHide={handleHide}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Block
          flexDirection={BlockFlexDirectionEnum.COLUMN}
          justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
          height={'100%'}
          overflowY={BlockOverflowYEnum.AUTO}
        >
          <Block gap={30} flexDirection={BlockFlexDirectionEnum.COLUMN}>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Название'}
                  placeholder={'Введите название'}
                />
              )}
              name={'name'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <TextArea
                  {...field}
                  {...fieldState}
                  label={'Описание'}
                  placeholder={'Введите описание'}
                />
              )}
              name={'description'}
              control={control}
            />
            <Block gap={5} flexDirection={BlockFlexDirectionEnum.COLUMN}>
              <Typography bold={true} variant={TypographyVariantEnum.ALTERNATIVE}>
                Ссылки
              </Typography>
              {fieldsLinks.map((field, index) => {
                return (
                  <Group key={field.id}>
                    <Block gap={15}>
                      <Block gap={5} flexDirection={BlockFlexDirectionEnum.COLUMN} width={'100%'}>
                        <Controller
                          render={({ field, fieldState }) => (
                            <Input {...field} {...fieldState} placeholder={'Введите название'} />
                          )}
                          name={`links.${index}.name`}
                          control={control}
                        />
                        <Controller
                          render={({ field, fieldState }) => (
                            <Input {...field} {...fieldState} placeholder={'Вставьте ссылку'} />
                          )}
                          name={`links.${index}.link`}
                          control={control}
                        />
                      </Block>
                      <IconButton onClick={() => removeLinks(index)}>
                        <TrashIcon />
                      </IconButton>
                    </Block>
                  </Group>
                );
              })}
              <AddButton onClick={() => appendLinks({ name: '', link: '' })}>
                <Typography fontSize={30}>+</Typography>
              </AddButton>
            </Block>
          </Block>
          <Block
            gap={15}
            justifyContent={
              props.id ? BlockJustifyContentEnum.SPACE_BETWEEN : BlockJustifyContentEnum.FLEX_END
            }
          >
            {props.id && (
              <Button
                variant={ButtonVariantEnum.WARNING}
                onClick={() => {
                  showModal(ModalsEnum.CONFIRMATION_MODAL, {
                    title: 'Удаление программы',
                    description: `Вы действительно хотите удалить программу?`,
                    onClick: () => removeMutation.mutate(),
                  });
                }}
              >
                Удалить
              </Button>
            )}
            <Block gap={15}>
              <Button width={'auto'} variant={ButtonVariantEnum.OUTLINED} onClick={handleHide}>
                Отменить
              </Button>
              <Button width={'auto'} type={ButtonTypeEnum.SUBMIT} disabled={mutation.isLoading}>
                Сохранить
              </Button>
            </Block>
          </Block>
        </Block>
      </Form>
    </Modal>
  );
});

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
`;

const AddButton = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid rgba(105, 108, 255, 0.2);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Group = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(105, 108, 255, 0.2);
  background: rgba(0, 0, 0, 0.1);
`;

export default connectModal(ModalsEnum.COURSE_MODAL)(CourseModal);
