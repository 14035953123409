import React, { FC, memo } from 'react';

const ExportIcon: FC = memo(() => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 26C19 26.5523 19.4477 27 20 27C20.5523 27 21 26.5523 21 26L19 26ZM20.7071 6.29289C20.3166 5.90237 19.6834 5.90237 19.2929 6.29289L12.9289 12.6569C12.5384 13.0474 12.5384 13.6805 12.9289 14.0711C13.3195 14.4616 13.9526 14.4616 14.3431 14.0711L20 8.41421L25.6569 14.0711C26.0474 14.4616 26.6805 14.4616 27.0711 14.0711C27.4616 13.6805 27.4616 13.0474 27.0711 12.6569L20.7071 6.29289ZM21 26L21 7L19 7L19 26L21 26Z'
        fill='#696CFF'
      />
      <path
        d='M6 23.5L6 31C6 32.6569 7.34315 34 9 34L31 34C32.6569 34 34 32.6569 34 31L34 23.5'
        stroke='#696CFF'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
});

export default ExportIcon;
