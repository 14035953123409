import React, { FC, forwardRef, memo, PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  BlockFlexEnum,
  BlockFlexWrapEnum,
  BlockJustifyContentEnum,
  BlockOverflowYEnum,
} from '../../config/enums';

interface Props {
  flexDirection?: BlockFlexDirectionEnum;
  gap?: number;
  width?: string;
  alignItems?: BlockAlignItemsEnum;
  justifyContent?: BlockJustifyContentEnum;
  height?: string;
  onClick?: VoidFunction;
  flexWrap?: BlockFlexWrapEnum;
  background?: string;
  border?: string;
  padding?: string;
  flex?: BlockFlexEnum;
  overflowY?: BlockOverflowYEnum;
  blockRef?: any;
  id?: string;
}

const Block: FC<Props & PropsWithChildren> = memo(
  ({
    children,
    flexDirection = BlockFlexDirectionEnum.ROW,
    gap = 0,
    width,
    alignItems,
    justifyContent,
    height,
    onClick,
    flexWrap,
    background,
    padding,
    flex,
    overflowY,
    blockRef,
    id,
  }) => {
    return (
      <Wrapper
        id={id}
        ref={blockRef}
        flexDirection={flexDirection}
        gap={gap}
        width={width}
        alignItems={alignItems}
        justifyContent={justifyContent}
        height={height}
        onClick={onClick}
        clickable={!!onClick}
        flexWrap={flexWrap}
        background={background}
        padding={padding}
        flex={flex}
        overflowY={overflowY}
      >
        {children}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{
  flexDirection: BlockFlexDirectionEnum;
  gap: number;
  width?: string;
  alignItems?: BlockAlignItemsEnum;
  justifyContent?: BlockJustifyContentEnum;
  height?: string;
  clickable: boolean;
  flexWrap?: BlockFlexWrapEnum;
  background?: string;
  padding?: string;
  flex?: BlockFlexEnum;
  overflowY?: BlockOverflowYEnum;
}>`
  display: flex;
  //flex: 0 0 auto;
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: ${({ gap }) => gap}px;
  width: ${({ width }) => width || 'auto'};
  ${({ alignItems }) => {
    if (alignItems) {
      return {
        alignItems,
      };
    }
  }}
  ${({ justifyContent }) => {
    if (justifyContent) {
      return {
        justifyContent,
      };
    }
  }}
  ${({ height }) => {
    if (height) {
      return {
        height,
      };
    }
  }}
  ${({ clickable }) => {
    if (clickable) {
      return {
        cursor: 'pointer',
      };
    }
  }}
  ${({ flexWrap }) => {
    if (flexWrap) {
      return {
        flexWrap,
      };
    }
  }}
  ${({ background }) => {
    if (background) {
      return {
        background,
      };
    }
  }}
  ${({ padding }) => {
    if (padding) {
      return {
        padding,
      };
    }
  }}
  ${({ flex }) => {
    if (flex) {
      return {
        flex,
      };
    }
  }}
  ${({ overflowY }) => {
    if (overflowY) {
      return {
        overflowY,
      };
    }
  }}
`;

export default Block;
