import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { tabItemIdType } from './type';
import { TabItemInterface } from '../../config/interfaces';
import Typography from '../typography';
import { TypographyWritingModeEnum } from '../../config/enums';

interface Props {
  onChange: (tab: tabItemIdType) => void;
  active: boolean;
}

const TabItem: FC<Props & TabItemInterface> = memo(({ onChange, id, label, active, action }) => {
  const handleChange = useCallback(() => {
    action ? action() : onChange(id);
  }, [onChange, id, active]);

  return (
    <Wrapper onClick={handleChange} active={active}>
      <Typography fontSize={16} color={active ? '#fff' : '#F7542E'} vertical={true}>
        {label}
      </Typography>
    </Wrapper>
  );
});

const Wrapper = styled.div<{ active: boolean }>`
  border-radius: 5px;
  border: 1px solid #f7542e;
  padding: 12px 1px;
  background: ${({ active }) => (active ? '#f7542e' : 'transparent')};
  cursor: pointer;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default TabItem;
