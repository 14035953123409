import React, { FC, memo, PropsWithChildren } from 'react';
import { useModal } from './useModal';

const ModalContext = React.createContext(undefined);

const ContentContainer: FC<PropsWithChildren> = memo(({ children }) => <>{children}</>);

const ModalProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const { modals, hideModal, showModal, hideAllModals } = useModal();

  return (
    <ModalContext.Provider value={{ modals, hideModal, showModal, hideAllModals }}>
      <ContentContainer>{children}</ContentContainer>
    </ModalContext.Provider>
  );
});

export { ModalProvider, ModalContext };
