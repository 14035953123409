import React, { memo, useCallback, useMemo, useRef } from 'react';
import { connectModal } from '../../../contexts/modal/connectModal';
import {
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  ButtonTypeEnum,
  ButtonVariantEnum,
  ModalPositionEnum,
  ModalsEnum,
} from '../../../config/enums';
import Modal from '../../../components/modal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from '../../../styles/components';
import Block from '../../../components/block';
import Button from '../../../components/button';
import Input from '../../../components/input';
import Checkbox from '../../../components/checkbox';
import { useExercise } from '../../../store/exercise';
import { fetchInstanceStorage } from '../../../utils/axios';
import { apiStorage } from '../../../config/api';

const schema = yup.object().shape({
  name: yup.string().required(),
  removable: yup.boolean().nullable(),
  editable: yup.boolean().nullable(),
});

const FileModal = memo(({ handleHide, name = '', removable = false, editable = false }: any) => {
  const fileRef = useRef<any>();
  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
      removable,
      editable,
    },
  });

  const sources = useExercise((state) => state.meta.sources);
  const watchedName = watch('name');

  const setSource = useExercise((state) => state.setSource);

  const onSubmit = useCallback(
    async (data: any) => {
      let params = { ...data };
      const file = fileRef.current?.files?.[0];
      if (file) {
        const formData = new FormData();
        formData.append('bucket', 'med-it');
        formData.append('path', '/');
        formData.append('file', file);
        const response = await fetchInstanceStorage({
          method: 'POST',
          url: apiStorage.simpleUpload,
          data: formData,
        });
        if (response.data?.url) {
          params = {
            ...data,
            link: response.data.url,
          };
        }
      }
      setSource(params, name);
      handleHide();
    },
    [name],
  );

  const disabled = useMemo(() => {
    return (
      !watchedName ||
      Object.keys(sources).some((fileName) => fileName === watchedName && fileName !== name)
    );
  }, [watchedName, sources, name]);

  return (
    <Modal handleHide={handleHide} position={ModalPositionEnum.CENTER}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Block
          flexDirection={BlockFlexDirectionEnum.COLUMN}
          justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
          height={'100%'}
          gap={20}
        >
          <Block gap={30} flexDirection={BlockFlexDirectionEnum.COLUMN}>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  label={'Название файла'}
                  placeholder={'Введите название'}
                />
              )}
              name={'name'}
              control={control}
            />
          </Block>
          <input type={'file'} ref={fileRef} />
          <Block gap={5} flexDirection={BlockFlexDirectionEnum.COLUMN}>
            <Controller
              render={({ field, fieldState }) => (
                <Checkbox {...field} {...fieldState} label={'Возможность удалять файл'} />
              )}
              name={'removable'}
              control={control}
            />
            <Controller
              render={({ field, fieldState }) => (
                <Checkbox {...field} {...fieldState} label={'Возможность редактировать файл'} />
              )}
              name={'editable'}
              control={control}
            />
          </Block>
          <Block gap={15} justifyContent={BlockJustifyContentEnum.FLEX_END}>
            <Button width={'auto'} variant={ButtonVariantEnum.OUTLINED} onClick={handleHide}>
              Отменить
            </Button>
            <Button width={'auto'} type={ButtonTypeEnum.SUBMIT} disabled={disabled}>
              Сохранить
            </Button>
          </Block>
        </Block>
      </Form>
    </Modal>
  );
});

export default connectModal(ModalsEnum.FILE_MODAL)(FileModal);
