export const getItemsInRow = () => {
  const width = window.innerWidth;
  if (width > 1600) {
    return 4;
  } else if (width > 1200) {
    return 3;
  } else if (width > 800) {
    return 2;
  }
  return 1;
};
