import React, { FC, memo, useContext, useEffect, useState } from 'react';
import Block from '../../../components/block';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  BlockFlexEnum,
  BlockFlexWrapEnum,
  BlockJustifyContentEnum,
  ModalsEnum,
  TypographyVariantEnum,
} from '../../../config/enums';
import IconButton from '../../../components/iconButton';
import Typography from '../../../components/typography';
import { ModalContext } from '../../../contexts/modal';
import { useExercise } from '../../../store/exercise';
import styled from 'styled-components';
import TrashIcon from '../../../icons/Trash';
import EditIcon from '../../../icons/Edit';
import Content from './Content';
import Result from './Result';
import { defaultFileName } from '../../../config/consts';
import { useApp } from '../../../store/app';

const Code: FC = memo(() => {
  const { showModal } = useContext(ModalContext);
  const isAdmin = useApp((state) => state.isAdmin);

  const sources = useExercise((state) => state.meta.sources);
  const removeSource = useExercise((state) => state.removeSource);
  const [selectedFile, setSelectedFile] = useState(sources ? Object.keys(sources)?.[0] || '' : '');

  useEffect(() => {
    if (sources) {
      if (Object.keys(sources).length > 0 && !selectedFile) {
        setSelectedFile(Object.keys(sources)[0]);
      } else if (Object.keys(sources).length === 0 && selectedFile) {
        setSelectedFile('');
      } else if (selectedFile && !Object.keys(sources).includes(selectedFile)) {
        setSelectedFile(Object.keys(sources)[0]);
      }
    }
  }, [sources, selectedFile]);

  return (
    <>
      <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={15} width={'50%'}>
        <Block
          gap={10}
          width={'100%'}
          alignItems={BlockAlignItemsEnum.CENTER}
          flex={BlockFlexEnum.FLEX_0_0_AUTO}
        >
          <Block flex={BlockFlexEnum.FLEX_1_1_AUTO} flexWrap={BlockFlexWrapEnum.WRAP}>
            {Object.keys(sources || {}).map((fileName) => {
              const meta = sources[fileName];
              return (
                <Block
                  key={`file/${fileName}`}
                  flexDirection={BlockFlexDirectionEnum.COLUMN}
                  height={'44px'}
                  onClick={() => setSelectedFile(fileName)}
                >
                  <Block
                    height={'40px'}
                    padding={'0 10px'}
                    gap={10}
                    alignItems={BlockAlignItemsEnum.CENTER}
                  >
                    <Typography fontSize={13} color={'#545454'}>
                      {fileName}
                    </Typography>
                    {isAdmin && !meta.link && (
                      <IconButton
                        onClick={() =>
                          showModal(ModalsEnum.FILE_MODAL, { ...meta, name: fileName })
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {fileName !== defaultFileName && (isAdmin || meta.removable) && (
                      <IconButton
                        onClick={() =>
                          showModal(ModalsEnum.CONFIRMATION_MODAL, {
                            title: 'Удаление файла',
                            description: `Вы действительно хотите удалить файл?`,
                            onClick: () => removeSource(fileName),
                            buttonTitle: 'Удалить',
                          })
                        }
                      >
                        <TrashIcon />
                      </IconButton>
                    )}
                  </Block>
                  {fileName === selectedFile && <Active />}
                </Block>
              );
            })}
          </Block>
          {isAdmin && (
            <Block flex={BlockFlexEnum.FLEX_0_0_AUTO}>
              <IconButton onClick={() => showModal(ModalsEnum.FILE_MODAL)}>
                <Typography variant={TypographyVariantEnum.ALTERNATIVE} fontSize={24}>
                  +
                </Typography>
              </IconButton>
            </Block>
          )}
        </Block>
        <Block width={'100%'} height={'100%'}>
          {selectedFile ? (
            <Content selectedFile={selectedFile} />
          ) : (
            <Block
              width={'100%'}
              alignItems={BlockAlignItemsEnum.CENTER}
              justifyContent={BlockJustifyContentEnum.CENTER}
            >
              <Typography>Не выбран файл</Typography>
            </Block>
          )}
        </Block>
      </Block>
      <Block width={'50%'} flexDirection={BlockFlexDirectionEnum.COLUMN} padding={'10px'}>
        <Result />
      </Block>
    </>
  );
});

const Active = styled.div`
  width: 100%;
  height: 4px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 2px;
`;

export default Code;
