import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { useApp } from '../../store/app';
import { useQuery } from 'react-query';
import { queries } from '../../config/queries';
import { fetchInstance } from '../../utils/axios';
import { api } from '../../config/api';
import Loader from '../../components/loader';
import Block from '../../components/block';
import {
  BlockAlignItemsEnum,
  BlockFlexDirectionEnum,
  BlockJustifyContentEnum,
  BlockOverflowYEnum,
  ModalsEnum,
  TypographyVariantEnum,
} from '../../config/enums';
import Typography from '../../components/typography';
import Button from '../../components/button';
import styled from 'styled-components';
import Input from '../../components/input';
import IconButton from '../../components/iconButton';
import ExportIcon from '../../icons/Export';
import Table from '../../components/table';
import { format } from 'date-fns';
import MoreIcon from '../../icons/More';
import MoreBlock from './MoreBlock';
import { ModalContext } from '../../contexts/modal';

const fields = [
  {
    label: 'Логин',
    handleField: (row) => (
      <Typography variant={TypographyVariantEnum.ALTERNATIVE}>{row.login}</Typography>
    ),
  },
  {
    label: 'ФИО',
    handleField: (row) => (
      <Typography variant={TypographyVariantEnum.ALTERNATIVE}>{row.fullname}</Typography>
    ),
  },
  {
    label: 'Роль',
    handleField: (row) => {
      const options = {
        user: 'Пользователь',
        admin: 'Админ',
      };
      return (
        <Typography variant={TypographyVariantEnum.ALTERNATIVE}>{options[row.role]}</Typography>
      );
    },
  },
  {
    label: 'Группа',
    handleField: (row) => {
      if (row.group) {
        return (
          <Block>
            <GroupWrapper>
              <Typography color={'#F7542E'}>{row.group}</Typography>
            </GroupWrapper>
          </Block>
        );
      }
      return null;
    },
  },
  {
    label: 'Примечание',
    handleField: (row) => (
      <Typography variant={TypographyVariantEnum.ALTERNATIVE}>{row.note}</Typography>
    ),
  },
  {
    label: 'Последняя отправка',
    handleField: (row) => <MoreBlock row={row} />,
  },
];

const Users: FC = memo(() => {
  const { showModal } = useContext(ModalContext);
  const setBreadcrumbs = useApp((state) => state.setBreadcrumbs);
  const [search, setSearch] = useState<any>('');

  const { data, isLoading } = useQuery(
    [queries.usersList],
    () =>
      fetchInstance({
        method: 'POST',
        url: api.usersSearch,
        data: {
          limit: 300,
          page: 1,
        },
      }),
    {
      cacheTime: 0,
    },
  );

  useEffect(() => {
    setBreadcrumbs([{ label: 'Пользователи', active: true }]);
  }, []);

  const onExport = async () => {
    try {
      const response = await fetchInstance({
        method: 'GET',
        url: api.usersExport,
        responseType: 'blob',
      });
      // @ts-ignore
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'users.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (e) {
      //
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <ButtonWrapper>
        <Button onClick={() => showModal(ModalsEnum.USER_MODAL)}>Добавить слушателя</Button>
      </ButtonWrapper>
      <Block
        gap={20}
        padding={'30px 20px'}
        flexDirection={BlockFlexDirectionEnum.COLUMN}
        overflowY={BlockOverflowYEnum.HIDDEN}
        height={'100%'}
      >
        <Typography fontSize={28} variant={TypographyVariantEnum.ALTERNATIVE}>
          Список пользователей
        </Typography>
        <Block
          alignItems={BlockAlignItemsEnum.CENTER}
          justifyContent={BlockJustifyContentEnum.SPACE_BETWEEN}
        >
          <Block width={'250px'}>
            <Input value={search} onChange={setSearch} placeholder={'Поиск'} />
          </Block>
          <IconButton onClick={onExport}>
            <ExportIcon />
          </IconButton>
        </Block>
        <Block overflowY={BlockOverflowYEnum.AUTO}>
          <Table fields={fields} data={data?.data || []} />
        </Block>
      </Block>
    </Wrapper>
  );
});

const ButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 100%;
  background: #f5f5f9;
`;

const GroupWrapper = styled.div`
  padding: 6px 8px;
  border-radius: 5px;
  border: 1px solid #f7542e;
  width: auto;
`;

export default Users;
