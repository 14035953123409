import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import IconButton from '../../components/iconButton';
import LogoutIcon from '../../icons/Logout';
import HeaderLogo from '../../assets/images/header-logo.png';
import Typography from '../../components/typography';
import Block from '../../components/block';
import { BlockFlexDirectionEnum, BlockFlexEnum, TypographyVariantEnum } from '../../config/enums';
import Breadcrumbs from '../../components/breadcrumbs';
import { useMutation } from 'react-query';
import { fetchInstance, setToken } from '../../utils/axios';
import { api } from '../../config/api';
import { useApp } from '../../store/app';
import Storage from '../../utils/storage';
import { useNavigate } from 'react-router-dom';
import UserIcon from '../../icons/User';
import { AnchorPopupContext } from '../../contexts/anchorPopup';

const Header: FC = memo(() => {
  const componentId = useId();
  const setProfile = useApp((state) => state.setProfile);
  const navigate = useNavigate();
  const isAdmin = useApp((state) => state.isAdmin);

  const { showPopup, hidePopup, changeComponent } = useContext(AnchorPopupContext);
  const [open, setOpen] = useState<boolean>(false);
  const isAnchor = useRef<boolean>(false);
  const timer = useRef<any>();
  const timeout = useRef<any>();
  const selectRef = useRef<any>();

  useEffect(() => {
    if (!open) {
      clearTimeout(timer.current);
      isAnchor.current = true;
      timer.current = setTimeout(() => {
        isAnchor.current = false;
      }, 200);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timeout.current);
    };
  }, []);

  const boxRef = useRef() as any;

  const mutation = useMutation(
    () =>
      fetchInstance({
        method: 'POST',
        url: api.authLogout,
      }),
    {
      onSuccess: () => {
        setProfile(null);
        setToken();
        Storage.removeToken();
      },
    },
  );

  const PopupComponent = useMemo(() => {
    return (
      <Box ref={boxRef}>
        <Block flexDirection={BlockFlexDirectionEnum.COLUMN} gap={5}>
          <Block
            onClick={() => {
              navigate('/users');
              hidePopup();
            }}
          >
            <Typography>Список пользователей</Typography>
          </Block>
          <Block
            onClick={() => {
              mutation.mutate();
              hidePopup();
            }}
          >
            <Typography color={'#F7542E'}>Выйти</Typography>
          </Block>
        </Block>
      </Box>
    );
  }, []);

  useEffect(() => {
    changeComponent(PopupComponent, componentId);
  }, [PopupComponent, componentId]);

  const onClose = useCallback(() => {
    setOpen(false);
    hidePopup();
  }, []);

  const toggleOpen = useCallback(() => {
    if (!isAnchor.current) {
      setOpen((state) => {
        if (!state) {
          clearTimeout(timeout.current);
          timeout.current = setTimeout(() => {
            showPopup({
              component: PopupComponent,
              anchorRef: selectRef.current,
              onClose,
              componentId,
              width: '210px',
            });
          }, 0);
        }
        return !state;
      });
    }
  }, [PopupComponent, componentId, onClose]);

  return (
    <Wrapper>
      <Block gap={55}>
        <Block gap={15} onClick={() => navigate('/')} flex={BlockFlexEnum.FLEX_0_0_AUTO}>
          <img
            src={HeaderLogo}
            alt={'header-logo'}
            style={{ maxWidth: '43px', maxHeight: '43px' }}
          />
          <Block flexDirection={BlockFlexDirectionEnum.COLUMN} flex={BlockFlexEnum.FLEX_0_0_AUTO}>
            <Typography fontSize={20} variant={TypographyVariantEnum.ALTERNATIVE}>
              Тренажёр MedIT
            </Typography>
            <Typography fontSize={14} color={'rgba(61, 63, 153, 0.50)'}>
              Цифровая кафедра МГМСУ
            </Typography>
          </Block>
        </Block>
        <Breadcrumbs />
      </Block>

      {!isAdmin ? (
        <IconButton onClick={mutation.mutate}>
          <LogoutIcon />
        </IconButton>
      ) : (
        <div ref={selectRef}>
          <IconButton onClick={toggleOpen}>
            <UserIcon />
          </IconButton>
        </div>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  height: 60px;
  border: 1px solid rgba(105, 108, 255, 0.2);
  background: #fefeff;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
`;

const Box = styled.div`
  position: absolute;
  right: 20px;
  padding: 10px;
  border-radius: 5px;
  background: white;
  border: 1px solid rgba(105, 108, 255, 0.3);
  z-index: 1;
`;

export default Header;
