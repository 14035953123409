import React, { FC, memo } from 'react';

const PlayIcon: FC = memo(() => {
  return (
    <svg width='17' height='19' viewBox='0 0 17 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.54663 2.55274C1.54663 1.78293 2.37997 1.30181 3.04663 1.68671L15.3139 8.76924C15.9806 9.15414 15.9806 10.1164 15.3139 10.5013L3.04663 17.5838C2.37997 17.9687 1.54663 17.4876 1.54663 16.7178L1.54663 2.55274Z'
        fill='#696CFF'
        stroke='#FEFEFF'
        strokeWidth='2'
      />
    </svg>
  );
});

export default PlayIcon;
