import { useCallback, useState } from 'react';

export const useAnchorPopup = () => {
  const [meta, setMeta] = useState(null);

  const showPopup = useCallback(
    ({ component, anchorRef, onClose, width = null, position = null, componentId }) => {
      setMeta({
        component,
        anchorRef,
        onClose,
        width,
        position,
        componentId,
      });
    },
    [],
  );

  const changeComponent = useCallback((component, componentId) => {
    setMeta((state) => {
      if (state?.anchorRef && state?.componentId === componentId) {
        return { ...state, component };
      }
      return state;
    });
  }, []);

  const hidePopup = useCallback(() => {
    setMeta(null);
  }, []);

  return { meta, showPopup, changeComponent, hidePopup };
};
